import { useState } from "react";
import Tab from "../components/Tab/Tab";
import { useSelector } from "react-redux";

import iconDown from "../assets/images/downIcon.png";
import { useNavigate } from "react-router-dom";
import useKrpano from "react-krpano-hooks";

export default function Home() {
  const navigate = useNavigate();

  const { containerRef, callKrpano } = useKrpano({
    globalFunctions: {
      logNewScene: (scene) => {
        console.log("Scene name: ", scene);
        scene === "the_wisteria" && navigate("/the-wisteria");
        scene === "binh_minh" && navigate("/binh-minh");
        scene === "thinh_vuong" && navigate("/thinh-vuong");
        scene === "thao_vien" && navigate("/thao-vien");
      },
    },
  });

  // const tabs = [
  //   {
  //     title: "Tổng quan",
  //   },
  //   {
  //     title: "Phân khu",
  //   },
  // ];
  const [tabActive, setTabActive] = useState(false);

  const subdivisions = [
    {
      title: "The wisteria",
      path: "/the-wisteria",
    },
    {
      title: "Bình minh",
      path: "/binh-minh",
    },
    {
      title: "Thịnh vượng",
      path: "/thinh-vuong",
    },
    {
      title: "Thảo viên",
      path: "/thao-vien",
      // path: "/tour",
    },
    {
      title: "Hoàng gia 1",
      // path: "/hoang-gia-1",
      path: "/tour",
    },
    {
      title: "Hoàng gia 2",
      // path: "/hoang-gia-2",
      path: "/tour",
    },
    {
      title: "Biệt thự",
      // path: "/biet-thu",
      path: "/tour",
    },
    {
      title: "Hỗn hợp",
      // path: "/hon-hop",
      path: "/tour",
    },
  ];

  const { locale } = useSelector((state) => state.language);

  return (
    // <div className="bg-[url('assets/images/bgTour.png')] bg-cover bg-fixed bg-center min-h-screen px-6 pt-4">
    //   <Tab
    //     tabs={tabs}
    //     tabActive={tabActive}
    //     setTabActive={setTabActive}
    //     cols={tabs.length}
    //     isBlur={true}
    //     width="40vw"
    //   />
    // </div>
    <div className="h-screen relative">
      {/* <iframe
        src={`${
          locale === "vi"
            ? "/file360/hinode-royal-park-toan-canh/index.html"
            : "/file360/hinode-royal-park-toan-canh/index.html"
        }`}
        title="hinode-royal-park-toan-canh"
        width="100%"
        height="100%"
      /> */}

      <div ref={containerRef} style={{ width: "100%", height: "100%" }} />

      {/* <Tab
        tabs={tabs}
        tabActive={tabActive}
        setTabActive={setTabActive}
        cols={tabs.length}
        isBlur={true}
        width="40vw"
        className={"fixed top-4 left-1/2 -translate-x-1/2"}
      /> */}
      <div
        className={`mb-8 flex justify-center items-center fixed top-4 left-1/2 -translate-x-1/2 w-[80%] md:w-[40%] lg:w-[30%]`}
      >
        <div
          className={`w-full grid grid-cols-2 gap-2 p-2 bg-[#fff] bg-opacity-20 backdrop-blur-sm border border-white
                        rounded-xl`}
        >
          <div
            className={`flex items-center justify-center text-center text-xs md:text-base
                text-primary bg-gradientMain shadow-2xl 
                  py-2 rounded-xl font-bold uppercase cursor-pointer`}
          >
            Tổng quan
          </div>
          <div
            onClick={() => {
              setTabActive(!tabActive);
            }}
            className={`flex items-center text-xs md:text-base justify-center gap-4 text-center text-sub bg-white 
                hover:text-primary hover:bg-gradientMain hover:shadow-2xl 
                  py-2 rounded-xl font-bold uppercase cursor-pointer relative`}
          >
            Phân khu <img src={iconDown} alt="iconDown" className="w-3" />
            {tabActive && (
              <div
                className=" absolute top-14 left-0 flex flex-col gap-2 w-full p-2 bg-[#fff] bg-opacity-20 
              backdrop-blur-md border border-white rounded-xl"
              >
                {subdivisions.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center gap-4 text-center text-sub bg-white 
                hover:text-primary hover:bg-gradientMain hover:shadow-2xl 
                  py-2 rounded-xl font-bold uppercase cursor-pointer text-xs md:text-base"
                    onClick={() => navigate(item.path)}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
