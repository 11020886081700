import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { useState } from "react";
import PopUpGuide from "../../components/PopUpGuide";

import tour from "../../assets/images/iconNav-wisteria/tour.png";
import houses from "../../assets/images/iconNav-wisteria/houses.png";
import ground from "../../assets/images/iconNav-wisteria/ground.png";
import utils from "../../assets/images/iconNav-wisteria/utils.png";
import ebrochure from "../../assets/images/iconNav-wisteria/ebrochure.png";

import tourHover from "../../assets/images/iconNav-wisteria/tourHover.png";
import housesHover from "../../assets/images/iconNav-wisteria/housesHover.png";
import groundHover from "../../assets/images/iconNav-wisteria/groundHover.png";
import utilsHover from "../../assets/images/iconNav-wisteria/utilsHover.png";
import ebrochureHover from "../../assets/images/iconNav-wisteria/ebrochureHover.png";

import btnBack from "../../assets/images/wisteria/icon/button-back.png";
import btnBackHover from "../../assets/images/wisteria/icon/button-back_hover.png";

import logo from "../../assets/images/iconNav-wisteria/logo.png";

export default function LayoutWisteria() {
  const menu = [
    {
      label: "Toàn cảnh",
      path: "/the-wisteria",
      icon: tour,
      iconHover: tourHover,
    },
    {
      label: "Tiện ích",
      path: "/the-wisteria/utilities",
      icon: utils,
      iconHover: utilsHover,
    },
    {
      label: "Mặt bằng",
      path: "/the-wisteria/ground",
      icon: ground,
      iconHover: groundHover,
    },
    {
      label: "Nhà Mẫu",
      path: "/the-wisteria/houses",
      icon: houses,
      iconHover: housesHover,
    },
    {
      label: "E-Brochure",
      path: "/the-wisteria/ebrochure",
      icon: ebrochure,
      iconHover: ebrochureHover,
    },
  ];

  // const [isShowPopUp, setIsShowPopUp] = useState(false);

  const path = useLocation().pathname;

  const handleOpenPopUp = () => {
    if (path === "/the-wisteria/ebrochure") {
      return;
    }
    // setIsShowPopUp(true);
  };

  const navigate = useNavigate();

  const [isHoveredBtn, setHoveredBtn] = useState(false);

  return (
    <div className="h-screen relative">
      {/* {isShowPopUp && <PopUpGuide setIsShowPopUp={setIsShowPopUp} />} */}
      <Outlet />
      <img
        src={!isHoveredBtn ? btnBack : btnBackHover}
        onMouseEnter={() => setHoveredBtn(true)}
        onMouseLeave={() => setHoveredBtn(false)}
        alt="btnBack"
        className="absolute z-30 top-2 left-2 md:top-4 md:left-4 cursor-pointer w-10 h-10 md:w-14 md:h-14 transition"
        onClick={() => navigate("/tour")}
      />

      <NavBar
        menu={menu}
        logo={logo}
        background={"bg-leafwhiteNav bg-[#50184B]"}
        colorHover={"text-[#50184B]"}
        backgroundActive={"bg-gradientNavWisteria"}
        theme="dark"
        setIsShowPopUp={handleOpenPopUp}
        backgroundHover={"hover:bg-gradientNavWisteria"}
        backgroundBorder={"bg-gradientNavWisteria"}
      />
    </div>
  );
}
