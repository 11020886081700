import React, { useState } from "react";
import MapZD from "../components/Location/MapZD";
import location2 from "../assets/images/location.png";

import giaoThongIcon from "../assets/images/icon/giao_thong.png";
import giaoDucIcon from "../assets/images/icon/giao_duc.png";
import yTeIcon from "../assets/images/icon/y_te.png";
import muaSamIcon from "../assets/images/icon/mua_sam.png";
import SvgAnimation from "../components/Location/SvgAnimation";
import { svgGroups, svgGroups2, svgGroups3 } from "../constants/data_location";

const Location = () => {
  // Animation settings
  const ANIMATION_DURATION = 0.2; // seconds
  const DELAY_BETWEEN_GROUPS = 0.15; // seconds

  const tabs = [
    // {
    //   title: "Tổng quan",
    //   color: "text-[#1D3557]",
    //   icon: "",
    // },
    {
      title: "Giáo dục",
      color: "text-white",
      bgColor: "bg-[#1D3557]",
      icon: giaoDucIcon,
    },
    {
      title: "Y tế",
      color: "text-white",
      bgColor: "bg-[#6A994E]",
      icon: yTeIcon,
    },
    {
      title: "Mua sắm",
      color: "text-white",
      bgColor: "bg-[#BC4749]",
      icon: muaSamIcon,
    },
    {
      title: "Giao thông",
      color: "text-white",
      bgColor: "bg-[#4b748f]",
      icon: giaoThongIcon,
    },
  ];

  const [tabActive, setTabActive] = useState(null);

  const [groupActive, setGroupActive] = useState([
    ...svgGroups,
    ...svgGroups2,
    ...svgGroups3,
  ]);

  console.log(groupActive);

  return (
    <div className="max-h-[91vh] h-[91vh] w-screen overflow-scroll">
      <div className="fixed z-50 right-2 md:right-8 p-2 bottom-24 bg-white bg-opacity-20 rounded-xl flex flex-col gap-2 backdrop-blur-sm border-white border-2">
        {tabs.map((tab, index) => (
          <div
            className={`
        px-4 py-2 flex items-center gap-3 text-xs md:text-sm font-semibold rounded-xl cursor-pointer
        ${
          tabActive === index
            ? "bg-gradientMain text-primary"
            : `${tab.bgColor} ${tab.color}`
        } hover:bg-gradientMain hover:text-primary
      `}
            onClick={() => {
              console.log("tabActive === index", tabActive === index);
              if (tabActive === index) {
                setTabActive(null);
                setGroupActive([...svgGroups, ...svgGroups2, ...svgGroups3]);
                return;
              }
              setTabActive(index);
              if (index === 0) {
                setGroupActive(svgGroups);
              } else if (index === 1) {
                setGroupActive(svgGroups2);
              } else {
                setGroupActive(svgGroups3);
              }
            }}
          >
            <img src={tab.icon} alt={tab.title} className="w-6" />
            {tab.title}
          </div>
        ))}
      </div>
      <MapZD
        map={location2}
        imageWidth={1920}
        imageHeight={1080}
        Svg={() => (
          <>
            <svg
              className="w-full absolute z-40"
              viewBox="0 0 1920 1080"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <style>
                {`
                  @keyframes fadeIn {
                    from {
                      opacity: 0;
                      transform: translateY(-20px);
                    }
                    to {
                      opacity: 1;
                      transform: translateY(0);
                    }
                  }
                  
                  .svg-group {
                    opacity: 0;
                    animation: fadeIn ${ANIMATION_DURATION}s ease-out forwards;
                  }
                  
                  ${groupActive
                    .map(
                      (_, index) => `
                    .svg-group:nth-child(${index + 1}) {
                      animation-delay: ${index * DELAY_BETWEEN_GROUPS}s;
                    }
                  `
                    )
                    .join("\n")}
                `}
              </style>

              {tabActive !== 3 && <SvgAnimation svgGroups={groupActive} />}
              {tabActive === 3 && (
                <g>
                  {/* line */}
                  <g clip-path="url(#clip0_1018_13337)">
                    <g clip-path="url(#clip1_1018_13337)">
                      <path
                        d="M1474.54 1091.4L1237.88 777.177L1228.18 757.788V742.391L1245.86 619.781L1253.28 492.61L1255.56 474.931L1285.21 340.346L1292.05 259.367L1306.31 233.134L1317.15 214.885L1288.06 125.922L1292.05 97.4082"
                        stroke="#6D462D"
                        stroke-width="13.6866"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M-0.191406 934.003L14.6358 932.293L201.686 940.276L416.681 922.028L461.162 916.325L628.253 876.976L680.148 865.57L692.694 864.43L954.451 883.249L982.965 885.53L1247.57 946.549L1271.52 952.822L1289.77 951.112L1309.16 945.409L1318.29 939.421L1322.85 936.427"
                        stroke="#6D462D"
                        stroke-width="13.6866"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.51953 153.296L95.6151 146.453L109.872 151.586L149.221 166.983H178.305L219.935 156.718L233.052 157.859L314.601 193.786L459.451 229.713L474.278 235.416L588.619 301.853L645.789 335.072L652.935 339.224M1921.07 884.961H1901.68L1886.28 888.953H1848.64L1834.96 892.945L1775.08 898.647L1753.41 882.68L1735.16 859.298L1695.24 814.817L1676.99 798.849L1662.16 789.725L1611.98 761.781L1574.91 747.524L1515.03 736.119L1495.64 710.456L1477.39 703.043L1460.29 700.762L1424.93 684.224L1395.27 653.999L1323.99 626.626L1243.01 624.345L1184.84 612.369L1077.63 574.16L872.902 492.04L830.416 461.103L819.795 453.369L814.484 449.501L811.829 447.568"
                        stroke="#6D462D"
                        stroke-width="13.6866"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.949219 637.459L133.253 622.632L146.94 591.837L171.462 611.797L186.859 596.399L206.819 586.134L217.084 579.291L292.36 527.396H333.42L373.34 525.685H418.391H432.078L453.178 527.396L489.106 531.388L495.949 530.818L541.001 520.553H556.969L566.093 521.123L672.164 549.067L711.513 568.456L739.457 591.267L755.425 590.126L796.484 563.894H835.263L970.989 622.062L1018.32 661.411L1034.29 669.965L1132.09 702.756L1180.99 719.151L1205.44 727.349"
                        stroke="#6D462D"
                        stroke-width="13.6866"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M568.376 889.522L549.556 806.262L539.291 795.426L515.91 794.286L529.027 755.507L522.183 719.009L571.797 519.413L571.227 491.469L587.195 431.02L590.616 391.101L594.608 371.141L600.311 360.306L603.162 310.121L639.66 229.142L684.142 204.62L695.547 196.636L706.382 184.661L718.358 169.834L758.278 113.376L761.699 108.814L839.257 39.8105"
                        stroke="#6D462D"
                        stroke-width="13.6866"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M875.183 1090.83L857.505 1060.03L750.293 711.596L744.59 641.452L755.425 594.689L757.136 571.878L756.209 551.205M920.805 97.4082L882.597 149.874L830.702 189.223L778.806 251.383L764.264 328.94L756.993 367.719L755.176 377.414"
                        stroke="#006730"
                        stroke-width="13.6866"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M754.521 529.794L768.417 550.686L743.376 552.275L754.521 529.794Z"
                        fill="#006730"
                      />
                      <path
                        d="M752.366 394.544L743.688 371L768.416 375.256L752.366 394.544Z"
                        fill="#006730"
                      />
                      <path
                        d="M794.4 435.13L819.348 437.816L804.548 458.079L794.4 435.13Z"
                        fill="#6D462D"
                      />
                      <path
                        d="M1341.05 925.485L1329.78 947.903L1316 926.933L1341.05 925.485Z"
                        fill="#6D462D"
                      />
                      <path
                        d="M1222.56 732.704L1198 737.837L1205.84 714L1222.56 732.704Z"
                        fill="#6D462D"
                      />
                      <path
                        d="M670.09 349.73L644.998 349.73L657.544 328L670.09 349.73Z"
                        fill="#6D462D"
                      />
                      <path
                        d="M854.727 26.4071L846.735 50.1924L830.132 31.3781L854.727 26.4071Z"
                        fill="#6D462D"
                      />
                    </g>
                    <path
                      d="M1295.45 76.7461L1304.85 100.011L1280 96.519L1295.45 76.7461Z"
                      fill="#6D462D"
                    />
                  </g>

                  {/* line run */}
                  <g
                    clip-path="url(#clip0_1018_13386)"
                    className="map_svg_active"
                  >
                    <g clip-path="url(#clip1_1018_13386)">
                      <path
                        d="M1474.54 1091.4L1237.88 777.177L1228.18 757.788V742.391L1245.86 619.781L1253.28 492.61L1255.56 474.931L1285.21 340.346L1292.05 259.367L1306.31 233.134L1317.15 214.885L1288.06 125.922L1292.05 97.4082"
                        stroke="url(#paint0_linear_1018_13386)"
                        stroke-width="2.2811"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-dasharray="4.56 6.84"
                      />
                      <path
                        d="M-0.191406 934.003L14.6358 932.293L201.686 940.276L416.68 922.028L461.162 916.325L628.253 876.976L680.148 865.57L692.694 864.43L954.451 883.249L982.965 885.53L1247.57 946.549L1271.52 952.822L1289.77 951.112L1309.16 945.409L1345.66 921.457"
                        stroke="url(#paint1_linear_1018_13386)"
                        stroke-width="2.2811"
                        stroke-linejoin="round"
                        stroke-dasharray="4.56 6.84"
                      />
                      <path
                        d="M0.949219 637.459L133.253 622.632L146.94 591.837L171.462 611.797L186.859 596.399L206.819 586.134L217.084 579.291L292.36 527.396H333.42L373.34 525.685H418.391H432.078L453.178 527.396L489.106 531.388L495.949 530.818L541.001 520.553H556.969L566.093 521.123L672.164 549.067L711.513 568.456L739.457 591.267L755.425 590.126L796.485 563.894H835.263L970.989 622.062L1018.32 661.411L1034.29 669.965L1229.89 735.547"
                        stroke="url(#paint2_linear_1018_13386)"
                        stroke-width="2.2811"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-dasharray="4.56 6.84"
                      />
                      <path
                        d="M568.376 889.522L549.556 806.262L539.291 795.426L515.91 794.286L529.027 755.507L522.183 719.009L571.797 519.413L571.227 491.469L587.195 431.02L590.616 391.101L594.608 371.141L600.311 360.306L603.162 310.121L639.66 229.142L684.142 204.62L695.547 196.636L706.382 184.661L718.358 169.834L758.278 113.376L761.699 108.814L839.257 39.8105"
                        stroke="url(#paint3_linear_1018_13386)"
                        stroke-width="2.2811"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-dasharray="4.56 6.84"
                      />
                    </g>
                    <path
                      d="M919 100L884 149L835 185L807.5 218L779.5 251L755.5 376.5"
                      stroke="url(#paint4_linear_1018_13386)"
                      stroke-width="2.2811"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-dasharray="4.56 6.84"
                    />
                    <path
                      d="M875.593 1090.42L857.915 1059.63L750.703 711.188L745 641.044L755.835 594.282L757.5 572.5L755.835 545"
                      stroke="url(#paint5_linear_1018_13386)"
                      stroke-width="2.2811"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-dasharray="4.56 6.84"
                    />
                    <path
                      d="M0 153.843L94.0956 147L108.352 152.132L147.702 167.53H176.786L218.416 157.265L231.532 158.406L313.082 194.333L457.932 230.26L472.759 235.963L658.5 342.5"
                      stroke="url(#paint6_linear_1018_13386)"
                      stroke-width="2.2811"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-dasharray="4.56 6.84"
                    />
                    <path
                      d="M1917.5 885.5H1902.5L1887 890.5L1848 889L1834.5 893L1775 898L1753.5 882L1695 814L1676.5 798.5L1612 762L1575.5 748L1515.5 736.5L1496 712L1478.5 704L1460.5 701.5L1425.5 685L1396 655.5L1324.5 627L1245.5 624L1187 613L1130.5 594.5L1077 573.5L1029 554.604L999 542.5L945.5 521.5L871.5 492.5L807 445"
                      stroke="url(#paint7_linear_1018_13386)"
                      stroke-width="2.2811"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-dasharray="4.56 6.84"
                    />
                  </g>
                </g>
              )}

<defs>
      <linearGradient
              id="paint0_linear_766_8227"
              x1="825.287"
              y1="224.871"
              x2="1108.17"
              y2="406.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
          
          {/* Match the timing with Tailwind's 1.6s */}
          <animateTransform
            attributeName="gradientTransform"
            type="translate"
            from="0"
            to="-1"
            dur="1.6s"
            repeatCount="indefinite"
          />
        </linearGradient>
      </defs>
            </svg>
          </>
        )}
      />
    </div>
  );
};

export default Location;
