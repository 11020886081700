import layoutw1_3 from "../assets/images/wisteria/mat-bang/w1/w1-3.png";
import layoutw1_4 from "../assets/images/wisteria/mat-bang/w1/w1-4.png";
import layoutw1_5 from "../assets/images/wisteria/mat-bang/w1/w1-5.png";
import layoutw1_6 from "../assets/images/wisteria/mat-bang/w1/w1-6.png";
import layoutw1_7 from "../assets/images/wisteria/mat-bang/w1/w1-7.png";
import layoutw1_23 from "../assets/images/wisteria/mat-bang/w1/w1-23.png";
import layoutw1_24 from "../assets/images/wisteria/mat-bang/w1/w1-24.png";

import layoutw2_3 from "../assets/images/wisteria/mat-bang/w2/w2-3.png";
import layoutw2_4 from "../assets/images/wisteria/mat-bang/w2/w2-4.png";
import layoutw2_5 from "../assets/images/wisteria/mat-bang/w2/w2-5.png";
import layoutw2_6 from "../assets/images/wisteria/mat-bang/w2/w2-6.png";
import layoutw2_7 from "../assets/images/wisteria/mat-bang/w2/w2-7.png";
import layoutw2_23 from "../assets/images/wisteria/mat-bang/w2/w2-23.png";
import layoutw2_24 from "../assets/images/wisteria/mat-bang/w2/w2-24.png";

import layoutw3_3 from "../assets/images/wisteria/mat-bang/w3/w3-3.png";
import layoutw3_4 from "../assets/images/wisteria/mat-bang/w3/w3-4.png";
import layoutw3_5 from "../assets/images/wisteria/mat-bang/w3/w3-5.png";
import layoutw3_6 from "../assets/images/wisteria/mat-bang/w3/w3-6.png";
import layoutw3_7 from "../assets/images/wisteria/mat-bang/w3/w3-7.png";
import layoutw3_23 from "../assets/images/wisteria/mat-bang/w3/w3-23.png";
import layoutw3_24 from "../assets/images/wisteria/mat-bang/w3/w3-24.png";

export const ground_layout = [
  // W1
  [
    {
      number: 3,
      layout: layoutw1_3,
      path: [
        {
          id: 1,
          poligon: "M1112 525V405H1248.89V441.681H1265V525H1112Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 85.6,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 2,
          poligon: "M1418 525V405H1281.11V441.681H1265V525H1418Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 84.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 3,
          poligon: null,
          x: 1462,
          y: 405,
          width: 231,
          height: 120,
          dt: 131.4,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 4,
          poligon: "M1693 596.354V555H1465V676H1616.33L1693 596.354Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.0,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 5,
          poligon: "M1465 555V676H1282.44V639.013H1265V555H1465Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 110.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 6,
          poligon: "M1067 555V676H1247.74V639.013H1265V555H1067Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 111.1,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 7,
          poligon: "M853 555V676H670.436V639.013H653V555H853Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 111.1,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS09",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 8,
          poligon: "M455 555V676H635.738V639.013H653V555H455Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 110.8,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS10",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 9,
          poligon: "M227 596.354V555H455V676H303.673L227 596.354Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.0,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS11",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 10,
          poligon: null,
          x: 225,
          y: 405,
          width: 231,
          height: 120,
          dt: 131.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 11,
          poligon: "M504 525V405H638.211V441.681H654V525H504Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 84.5,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12A",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 12,
          poligon: "M851 525V405H669.673V441.681H654V525H851Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 112.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12B",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
      ],
    },
    {
      number: 4,
      layout: layoutw1_4,
      path: [
        {
          id: 1,
          poligon: "M1112 525V405H1248.89V441.681H1265V525H1112Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 85.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 2,
          poligon: "M1418 525V405H1281.11V441.681H1265V525H1418Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 84.6,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 3,
          poligon: null,
          x: 1462,
          y: 405,
          width: 231,
          height: 120,
          dt: 131.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 4,
          poligon: "M1693 596.013V555H1465V675H1616.33L1693 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 113.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 5,
          poligon: "M1465 555V675H1282.44V638.319H1265V555H1465Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 110.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 6,
          poligon: "M1114 555V675H1251.84V638.319H1265V555H1114Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 85.4,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 7,
          poligon: "M978.5 675V638.077H960V555H1112V675H978.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 85.2,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS07",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 8,
          poligon: "M940.5 675H808V555H960V638.077H940.5V675Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 85.2,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS08",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 9,
          poligon: "M806 555V675H666.338V638.319H653V555H806Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 85.4,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS09",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 10,
          poligon: "M455 555V675H635.738V638.319H653V555H455Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 110.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS10",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 11,
          poligon: "M227 596.013V555H455V675H303.673L227 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.0,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS11",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 12,
          poligon: null,
          x: 225,
          y: 405,
          width: 231,
          height: 120,
          dt: 131.4,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 13,
          poligon: "M504 525V405H638.211V441.681H654V525H504Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 84.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12A",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 14,
          poligon: "M851 525V405H669.673V441.681H654V525H851Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 112.5,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12B",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
      ],
    },
    {
      number: 5,
      layout: layoutw1_5,
      path: [
        {
          id: 1,
          poligon: "M1112 525V405H1248.89V441.681H1265V525H1112Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 88.0,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 2,
          poligon: "M1418 525V405H1281.11V441.681H1265V525H1418Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 86.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 3,
          poligon: null,
          x: 1462,
          y: 405,
          width: 231,
          height: 120,
          dt: 133.8,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 4,
          poligon: "M1693 596.013V555H1465V675H1616.33L1693 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 116.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 5,
          poligon: "M1465 555V675H1282.44V638.319H1265V555H1465Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 113.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 6,
          poligon: "M1114 555V675H1251.84V638.319H1265V555H1114Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 87.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 7,
          poligon: "M978.5 675V638.077H960V555H1112V675H978.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 87.5,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS07",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 8,
          poligon: "M940.5 675H808V555H960V638.077H940.5V675Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 87.5,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS08",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 9,
          poligon: "M806 555V675H666.338V638.319H653V555H806Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 87.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS09",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 10,
          poligon: "M455 555V675H635.738V638.319H653V555H455Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 113.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS10",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 11,
          poligon: "M227 596.013V555H455V675H303.673L227 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 116.4,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS11",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 12,
          poligon: null,
          x: 225,
          y: 405,
          width: 231,
          height: 120,
          dt: 133.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 13,
          poligon: "M504 525V405H638.211V441.681H654V525H504Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 87.0,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12A",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 14,
          poligon: "M851 525V405H669.673V441.681H654V525H851Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-PS12B",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
      ],
    },
    {
      number: 6,
      layout: layoutw1_6,
      path: [
        {
          id: 1,
          poligon: "M1112 525V405H1248.89V441.681H1265V525H1112Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.8,
          transform: null,
          apartmentLayout: 5,
          code: "W1-01",
          typeRoom: 0,
          tower: 1,
          floor: 6,
        },
        {
          id: 2,
          poligon: "M1418 525V405H1281.11V441.681H1265V525H1418Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 81.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-02",
          typeRoom: 0,
          tower: 1,
          floor: 6,
        },
        {
          id: 3,
          poligon: null,
          x: 1462,
          y: 405,
          width: 231,
          height: 120,
          dt: 131.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-03",
          typeRoom: 3,
          tower: 1,
          floor: 6,
        },
        {
          id: 4,
          poligon: "M1693 596.013V555H1465V675H1616.33L1693 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 113.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-03A",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
        {
          id: 5,
          poligon: "M1465 555V675H1282.44V638.319H1265V555H1465Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 107.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-05",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
        {
          id: 6,
          poligon: "M1114 555V675H1251.84V638.319H1265V555H1114Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.4,
          transform: null,
          apartmentLayout: 5,
          code: "W1-06",
          typeRoom: 0,
          tower: 1,
          floor: 6,
        },
        {
          id: 7,
          poligon: "M978.5 675V638.077H960V555H1112V675H978.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-07",
          typeRoom: 0,
          tower: 1,
          floor: 6,
        },
        {
          id: 8,
          poligon: "M940.5 675H808V555H960V638.077H940.5V675Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-08",
          typeRoom: 0,
          tower: 1,
          floor: 6,
        },
        {
          id: 9,
          poligon: "M806 555V675H666.338V638.319H653V555H806Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.4,
          transform: null,
          apartmentLayout: 5,
          code: "W1-09",
          typeRoom: 0,
          tower: 1,
          floor: 6,
        },
        {
          id: 10,
          poligon: "M455 555V675H635.738V638.319H653V555H455Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 107.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-10",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
        {
          id: 11,
          poligon: "M227 596.013V555H455V675H303.673L227 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 113.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-11",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
        {
          id: 12,
          poligon: null,
          x: 225,
          y: 405,
          width: 231,
          height: 120,
          dt: 131.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-12",
          typeRoom: 3,
          tower: 1,
          floor: 6,
        },
        {
          id: 13,
          poligon: "M504 525V405H638.211V441.681H654V525H504Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 81.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-12A",
          typeRoom: 0,
          tower: 1,
          floor: 6,
        },
        {
          id: 14,
          poligon: "M851 525V405H669.673V441.681H654V525H851Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 109.6,
          transform: null,
          apartmentLayout: 5,
          code: "W1-12B",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
      ],
    },
    {
      number: 7,
      layout: layoutw1_7,
      path: [
        {
          id: 1,
          poligon: "M1112 525V405H1248.89V441.681H1265V525H1112Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 83.3,
          transform: null,
          apartmentLayout: 5,
          code: "W1-01",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 2,
          poligon: "M1418 525V405H1281.11V441.681H1265V525H1418Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.4,
          transform: null,
          apartmentLayout: 5,
          code: "W1-02",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 3,
          poligon: null,
          x: 1462,
          y: 405,
          width: 231,
          height: 120,
          dt: 132.2,
          transform: null,
          apartmentLayout: 5,
          code: "W1-03",
          typeRoom: 3,
          tower: 1,
          floor: 7,
        },
        {
          id: 4,
          poligon: "M1693 596.013V555H1465V675H1616.33L1693 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.5,
          transform: null,
          apartmentLayout: 5,
          code: "W1-03A",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 5,
          poligon: "M1465 555V675H1282.44V638.319H1265V555H1465Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 108.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-05",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 6,
          poligon: "M1114 555V675H1251.84V638.319H1265V555H1114Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-06",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 7,
          poligon: "M978.5 675V638.077H960V555H1112V675H978.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.8,
          transform: null,
          apartmentLayout: 5,
          code: "W1-07",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 8,
          poligon: "M940.5 675H808V555H960V638.077H940.5V675Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-08",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 9,
          poligon: "M806 555V675H666.338V638.319H653V555H806Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.9,
          transform: null,
          apartmentLayout: 5,
          code: "W1-09",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 10,
          poligon: "M455 555V675H635.738V638.319H653V555H455Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 108.7,
          transform: null,
          apartmentLayout: 5,
          code: "W1-10",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 11,
          poligon: "M227 596.013V555H455V675H303.673L227 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.5,
          transform: null,
          apartmentLayout: 5,
          code: "W1-11",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 12,
          poligon: null,
          x: 225,
          y: 405,
          width: 231,
          height: 120,
          dt: 132.2,
          transform: null,
          apartmentLayout: 5,
          code: "W1-12",
          typeRoom: 3,
          tower: 1,
          floor: 7,
        },
        {
          id: 13,
          poligon: "M504 525V405H638.211V441.681H654V525H504Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.5,
          transform: null,
          apartmentLayout: 5,
          code: "W1-12A",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 14,
          poligon: "M851 525V405H669.673V441.681H654V525H851Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 110.0,
          transform: null,
          apartmentLayout: 5,
          code: "W1-12B",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
      ],
    },
    {
      number: 23,
      layout: layoutw1_23,
      path: [
        {
          id: 1,
          poligon: "M1112 525V405H1248.89V441.681H1265V525H1112Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 154.45,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL01",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 2,
          poligon: "M1418 525V405H1281.11V441.681H1265V525H1418Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.25,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL02",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 3,
          poligon: null,
          x: 1462,
          y: 405,
          width: 231,
          height: 120,
          dt: 243.05,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL03",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 4,
          poligon: "M1693 596.013V555H1465V675H1616.33L1693 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 210.94,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL03A",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 5,
          poligon: "M1465 555V675H1282.44V638.319H1265V555H1465Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 205.35,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL05",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 6,
          poligon: "M1114 555V675H1251.84V638.319H1265V555H1114Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.25,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL06",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 7,
          poligon: "M978.5 675V638.077H960V555H1112V675H978.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.15,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL07",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 8,
          poligon: "M940.5 675H808V555H960V638.077H940.5V675Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.15,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL08",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 9,
          poligon: "M806 555V675H666.338V638.319H653V555H806Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.35,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL09",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 10,
          poligon: "M455 555V675H635.738V638.319H653V555H455Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 205.16,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL10",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 11,
          poligon: "M227 596.013V555H455V675H303.673L227 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 210.95,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL11",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 12,
          poligon: null,
          x: 225,
          y: 405,
          width: 231,
          height: 120,
          dt: 243.15,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL12",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 13,
          poligon: "M504 525V405H638.211V441.681H654V525H504Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.25,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL12A",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 14,
          poligon: "M851 525V405H669.673V441.681H654V525H851Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 205.75,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL12B",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
      ],
    },
    {
      number: 24,
      layout: layoutw1_24,
      path: [
        {
          id: 1,
          poligon: "M1112 525V405H1248.89V441.681H1265V525H1112Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 154.45,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL01",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 2,
          poligon: "M1418 525V405H1281.11V441.681H1265V525H1418Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.25,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL02",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 3,
          poligon: null,
          x: 1462,
          y: 405,
          width: 231,
          height: 120,
          dt: 243.05,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL03",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 4,
          poligon: "M1693 596.013V555H1465V675H1616.33L1693 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 210.94,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL03A",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 5,
          poligon: "M1465 555V675H1282.44V638.319H1265V555H1465Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 205.35,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL05",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 6,
          poligon: "M1114 555V675H1251.84V638.319H1265V555H1114Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.25,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL06",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 7,
          poligon: "M978.5 675V638.077H960V555H1112V675H978.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.15,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL07",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 8,
          poligon: "M940.5 675H808V555H960V638.077H940.5V675Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.15,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL08",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 9,
          poligon: "M806 555V675H666.338V638.319H653V555H806Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.35,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL09",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 10,
          poligon: "M455 555V675H635.738V638.319H653V555H455Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 205.16,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL10",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 11,
          poligon: "M227 596.013V555H455V675H303.673L227 596.013Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 210.95,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL11",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 12,
          poligon: null,
          x: 225,
          y: 405,
          width: 231,
          height: 120,
          dt: 243.15,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL12",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 13,
          poligon: "M504 525V405H638.211V441.681H654V525H504Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 153.25,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL12A",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 14,
          poligon: "M851 525V405H669.673V441.681H654V525H851Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 205.75,
          transform: null,
          apartmentLayout: 5,
          code: "W1-DL12B",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
      ],
    },
  ],

  // W2
  [
    {
      number: 3,
      layout: layoutw2_3,
      path: [
        {
          id: 1,
          poligon: "M1361 570V848H1145V771.5H1113V570H1361Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 80.3,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 2,
          poligon: "M801 570V848H1079.5V771.5H1113V570H801Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 101.4,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 3,
          poligon: null,
          x: 367,
          y: 570,
          width: 434,
          height: 278,
          dt: 142.1,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 4,
          poligon: null,
          x: 367,
          y: 230,
          width: 350,
          height: 283,
          dt: 113.3,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 5,
          poligon: "M799 513V230H1080V303.5H1114V513H799Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 101.3,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 6,
          poligon: "M1545 513V230H1147V303.5H1114V513H1545Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 140.2,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
      ],
    },
    {
      number: 4,
      layout: layoutw2_4,
      path: [
        {
          id: 1,
          poligon: "M1361 570V848H1145V771.5H1113V570H1361Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 80.2,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 2,
          poligon: "M801 570V848H1079.5V771.5H1113V570H801Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 101.6,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 3,
          poligon: null,
          x: 367,
          y: 570,
          width: 434,
          height: 278,
          dt: 142.3,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 4,
          poligon: null,
          x: 367,
          y: 230,
          width: 350,
          height: 283,
          dt: 113.5,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 5,
          poligon: "M799 513V230H1080V303.5H1114V513H799Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 101.5,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 6,
          poligon: "M1545 513V230H1147V303.5H1114V513H1545Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 140.4,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
      ],
    },
    {
      number: 5,
      layout: layoutw2_5,
      path: [
        {
          id: 1,
          poligon: "M1207 566V801H1024.1V736.333H997V566H1207Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.6,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 2,
          poligon: "M733 566V801H968.654V736.333H997V566H733Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 104.0,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 3,
          poligon: null,
          x: 367,
          y: 566,
          width: 366,
          height: 235,
          dt: 144.7,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 4,
          poligon: null,
          x: 367,
          y: 279,
          width: 294,
          height: 234,
          dt: 115.9,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 5,
          poligon: "M733 513V279H968.505V339.774H997V513H733Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 103.9,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 6,
          poligon: "M1359 513V279H1024.72V339.774H997V513H1359Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 142.8,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 7,
          poligon: null,
          x: 1359,
          y: 279,
          width: 196,
          height: 234,
          dt: 76.6,
          transform: null,
          apartmentLayout: 5,
          code: "W2-PS07",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
      ],
    },
    {
      number: 6,
      layout: layoutw2_6,
      path: [
        {
          id: 1,
          poligon: null,
          x: 1351,
          y: 279,
          width: 362,
          height: 237,
          dt: 135.7,
          transform: null,
          apartmentLayout: 5,
          code: "W2-01",
          typeRoom: 4,
          tower: 1,
          floor: 6,
        },
        {
          id: 2,
          poligon: "M1348 516V279H1023.87V340.553H997V516H1348Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 137.9,
          transform: null,
          apartmentLayout: 5,
          code: "W2-02",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
        {
          id: 3,
          poligon: "M733 516V279H965.829V340.553H994V516H733Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.1,
          transform: null,
          apartmentLayout: 5,
          code: "W2-03",
          typeRoom: 1,
          tower: 1,
          floor: 6,
        },
        {
          id: 4,
          poligon: null,
          x: 367,
          y: 279,
          width: 298,
          height: 237,
          dt: 113.7,
          transform: null,
          apartmentLayout: 5,
          code: "W2-03A",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
        {
          id: 5,
          poligon: null,
          x: 367,
          y: 563,
          width: 366,
          height: 238,
          dt: 141.8,
          transform: null,
          apartmentLayout: 5,
          code: "W2-05",
          typeRoom: 4,
          tower: 1,
          floor: 6,
        },
        {
          id: 6,
          poligon: "M733 563V801H964.191V735.507H992V563H733Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.2,
          transform: null,
          apartmentLayout: 5,
          code: "W2-06",
          typeRoom: 1,
          tower: 1,
          floor: 6,
        },
        {
          id: 7,
          poligon: "M1351 563V801H1021.5V735.507H995V563H1351Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 138.4,
          transform: null,
          apartmentLayout: 5,
          code: "W2-07",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
      ],
    },
    {
      number: 7,
      layout: layoutw2_7,
      path: [
        {
          id: 1,
          poligon: "M904 319H946V353H971V319H1105V447H904V319Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 136.1,
          transform: null,
          apartmentLayout: 5,
          code: "W2-01",
          typeRoom: 4,
          tower: 1,
          floor: 7,
        },
        {
          id: 2,
          poligon: "M904 447V319H722V354H707V447H904Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 138.1,
          transform: null,
          apartmentLayout: 5,
          code: "W2-02",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 3,
          poligon: "M563 447V319H691V354H707V447H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.5,
          transform: null,
          apartmentLayout: 5,
          code: "W2-03",
          typeRoom: 1,
          tower: 1,
          floor: 7,
        },
        {
          id: 4,
          poligon: "M363 447V319H523L523 447H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.2,
          transform: null,
          apartmentLayout: 5,
          code: "W2-03A",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 5,
          poligon: "M363 604V476H563L563 604H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 142.2,
          transform: null,
          apartmentLayout: 5,
          code: "W2-05",
          typeRoom: 4,
          tower: 1,
          floor: 7,
        },
        {
          id: 6,
          poligon: "M563 476V604H691V569H707V476H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.6,
          transform: null,
          apartmentLayout: 5,
          code: "W2-06",
          typeRoom: 1,
          tower: 1,
          floor: 7,
        },
        {
          id: 7,
          poligon: "M905.5 476V604H723V569H707V476H905.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 138.8,
          transform: null,
          apartmentLayout: 5,
          code: "W2-07",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 8,
          poligon: "M1105 761H1063V727H1038V761H904V633H1105V761Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 136.1,
          transform: null,
          apartmentLayout: 5,
          code: "W2-08",
          typeRoom: 4,
          tower: 1,
          floor: 7,
        },
        {
          id: 9,
          poligon: "M1105 633V761H1233V726H1249V633H1105Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.6,
          transform: null,
          apartmentLayout: 5,
          code: "W2-09",
          typeRoom: 1,
          tower: 1,
          floor: 7,
        },
        {
          id: 10,
          poligon: "M1355.5 633V761H1265V726H1249V633H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 73.1,
          transform: null,
          apartmentLayout: 5,
          code: "W2-10",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 11,
          poligon: "M1557 633V761H1356V633H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 142.2,
          transform: null,
          apartmentLayout: 5,
          code: "W2-11",
          typeRoom: 4,
          tower: 1,
          floor: 7,
        },
        {
          id: 12,
          poligon: "M1557 476V604H1395V476H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.2,
          transform: null,
          apartmentLayout: 5,
          code: "W2-12",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 13,
          poligon: "M1355.5 604V476H1265V511H1249V604H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 72.8,
          transform: null,
          apartmentLayout: 5,
          code: "W2-12A",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 14,
          poligon: "M1104 604V476H1232.5V511H1248.5V604H1104Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 100.1,
          transform: null,
          apartmentLayout: 5,
          code: "W2-12B",
          typeRoom: 1,
          tower: 1,
          floor: 7,
        },
      ],
    },
    {
      number: 23,
      layout: layoutw2_23,
      path: [
        {
          id: 1,
          poligon: "M904 319H946V353H971V319H1105V447H904V319Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 256.115,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL01",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 2,
          poligon: "M904 447V319H722V354H707V447H904Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 257.815,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL02",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 3,
          poligon: "M563 447V319H691V354H707V447H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 184.415,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL03",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 4,
          poligon: "M363 447V319H523L523 447H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 203.115,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL03A",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 5,
          poligon: "M363 604V476H563L563 604H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 258.815,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL05",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 6,
          poligon: "M563 476V604H691V569H707V476H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 185.715,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL06",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 7,
          poligon: "M905.5 476V604H723V569H707V476H905.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 258.515,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL07",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 8,
          poligon: "M1105 761H1063V727H1038V761H904V633H1105V761Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 256.415,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL08",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 9,
          poligon: "M1105 633V761H1233V726H1249V633H1105Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 184.305,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL09",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 10,
          poligon: "M1355.5 633V761H1265V726H1249V633H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 191.915,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL10",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 11,
          poligon: "M1557 633V761H1356V633H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 200.115,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL11",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 12,
          poligon: "M1557 476V604H1395V476H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 203.315,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL12",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 13,
          poligon: "M1355.5 604V476H1265V511H1249V604H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 73.0,
          transform: null,
          apartmentLayout: 5,
          code: "W2-12A",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 14,
          poligon: "M1104 604V476H1232.5V511H1248.5V604H1104Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 186.515,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL12B",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
      ],
    },
    {
      number: 24,
      layout: layoutw2_24,
      path: [
        {
          id: 1,
          poligon: "M904 319H946V353H971V319H1105V447H904V319Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 256.115,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL01",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 2,
          poligon: "M904 447V319H722V354H707V447H904Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 257.815,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL02",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 3,
          poligon: "M563 447V319H691V354H707V447H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 184.415,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL03",
          typeRoom: 3,
          tower: 1,
          floor: 24,
        },
        {
          id: 4,
          poligon: "M363 447V319H523L523 447H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 203.115,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL03A",
          typeRoom: 2,
          tower: 1,
          floor: 24,
        },
        {
          id: 5,
          poligon: "M363 604V476H563L563 604H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 258.815,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL05",
          typeRoom: 2,
          tower: 1,
          floor: 24,
        },
        {
          id: 6,
          poligon: "M563 476V604H691V569H707V476H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 185.715,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL06",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 7,
          poligon: "M905.5 476V604H723V569H707V476H905.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 258.515,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL07",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 8,
          poligon: "M1105 761H1063V727H1038V761H904V633H1105V761Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 256.415,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL08",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 9,
          poligon: "M1105 633V761H1233V726H1249V633H1105Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 184.305,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL09",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 10,
          poligon: "M1355.5 633V761H1265V726H1249V633H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 191.915,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL10",
          typeRoom: 2,
          tower: 1,
          floor: 24,
        },
        {
          id: 11,
          poligon: "M1557 633V761H1356V633H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 200.15,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL11",
          typeRoom: 2,
          tower: 1,
          floor: 24,
        },
        {
          id: 12,
          poligon: "M1557 476V604H1395V476H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 203.315,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL12",
          typeRoom: 3,
          tower: 1,
          floor: 24,
        },
        {
          id: 13,
          poligon: "M1355.5 604V476H1265V511H1249V604H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 73.0,
          transform: null,
          apartmentLayout: 5,
          code: "W2-12A",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 14,
          poligon: "M1104 604V476H1232.5V511H1248.5V604H1104Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 186.515,
          transform: null,
          apartmentLayout: 5,
          code: "W2-DL12B",
          typeRoom: 2,
          tower: 1,
          floor: 24,
        },
      ],
    },
  ],

  // W3
  [
    {
      number: 3,
      layout: layoutw3_3,
      path: [
        {
          id: 1,
          poligon: "M1554 563V769H1234V563H1554Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 139.2,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 2,
          poligon: "M1233.5 563V769H942.5V715.498H918V563H1233.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 140.2,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 3,
          poligon: "M686 563V769H889.5V715.498H918V563H686Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 101.5,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 4,
          poligon: null,
          x: null,
          y: null,
          width: 322,
          height: 206,
          dt: 142.0,
          transform: "matrix(1 0 0 -1 364 769)",
          apartmentLayout: 5,
          code: "W3-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 5,
          poligon: null,
          x: null,
          y: null,
          width: 261,
          height: 208,
          dt: 113.4,
          transform: "matrix(1 0 0 -1 364 518)",
          apartmentLayout: 5,
          code: "W3-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 6,
          poligon: "M686 518V310H893.09V367.237H918V518H686Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 101.3,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
        {
          id: 7,
          poligon: "M1100 518V310H945.097V367.237H918V518H1100Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 80.4,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS07",
          typeRoom: 5,
          tower: 1,
          floor: 3,
        },
      ],
    },
    {
      number: 4,
      layout: layoutw3_4,
      path: [
        {
          id: 1,
          poligon: "M1234 769H1300.5V716H1340V769H1554V563H1234V769Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 139.5,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 2,
          poligon: "M1233.5 563V769H945.717V715.498H918V563H1233.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 140.4,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 3,
          poligon: "M686 563V769H889.5V715.498H918V563H686Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 101.6,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 4,
          poligon: null,
          x: null,
          y: null,
          width: 322,
          height: 206,
          dt: 142.3,
          transform: "matrix(1 0 0 -1 364 769)",
          apartmentLayout: 5,
          code: "W3-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 5,
          poligon: null,
          x: null,
          y: null,
          width: 261,
          height: 208,
          dt: 113.5,
          transform: "matrix(1 0 0 -1 364 518)",
          apartmentLayout: 5,
          code: "W3-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 6,
          poligon: "M686 518V310H893.09V367.237H918V518H686Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 101.5,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
        {
          id: 7,
          poligon: "M1100 518V310H945.097V367.237H918V518H1100Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 80.2,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS07",
          typeRoom: 5,
          tower: 1,
          floor: 4,
        },
      ],
    },
    {
      number: 5,
      layout: layoutw3_5,
      path: [
        {
          id: 1,
          poligon: "M1234 769H1300.5V716H1340V769H1554V563H1234V769Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 141.9,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS01",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 2,
          poligon: "M1233.5 563V769H945.717V715.498H918V563H1233.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 142.8,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS02",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 3,
          poligon: "M686 563V769H889.5V715.498H918V563H686Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 103.9,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS03",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 4,
          poligon: null,
          x: null,
          y: null,
          width: 322,
          height: 206,
          dt: 144.7,
          transform: "matrix(1 0 0 -1 364 769)",
          apartmentLayout: 5,
          code: "W3-PS03A",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 5,
          poligon: null,
          x: null,
          y: null,
          width: 261,
          height: 208,
          dt: 115.9,
          transform: "matrix(1 0 0 -1 364 518)",
          apartmentLayout: 5,
          code: "W3-PS05",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 6,
          poligon: "M686 518V310H893.09V367.237H918V518H686Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 103.8,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS06",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
        {
          id: 7,
          poligon: "M1100 518V310H945.097V367.237H918V518H1100Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 82.5,
          transform: null,
          apartmentLayout: 5,
          code: "W3-PS07",
          typeRoom: 5,
          tower: 1,
          floor: 5,
        },
      ],
    },
    {
      number: 6,
      layout: layoutw3_6,
      path: [
        {
          id: 1,
          poligon: "M1234 769H1300.5V716H1340V769H1554V563H1234V769Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 135.4,
          transform: null,
          apartmentLayout: 5,
          code: "W3-01",
          typeRoom: 4,
          tower: 1,
          floor: 6,
        },
        {
          id: 2,
          poligon: "M1233.5 563V769H945.717V715.498H918V563H1233.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 137.9,
          transform: null,
          apartmentLayout: 5,
          code: "W3-02",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
        {
          id: 3,
          poligon: "M686 563V769H889.5V715.498H918V563H686Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.2,
          transform: null,
          apartmentLayout: 5,
          code: "W3-03",
          typeRoom: 1,
          tower: 1,
          floor: 6,
        },
        {
          id: 4,
          poligon: null,
          x: null,
          y: null,
          width: 322,
          height: 206,
          dt: 141.8,
          transform: "matrix(1 0 0 -1 364 769)",
          apartmentLayout: 5,
          code: "W3-03A",
          typeRoom: 4,
          tower: 1,
          floor: 6,
        },
        {
          id: 5,
          poligon: null,
          x: null,
          y: null,
          width: 261,
          height: 208,
          dt: 113.7,
          transform: "matrix(1 0 0 -1 364 518)",
          apartmentLayout: 5,
          code: "W3-05",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
        {
          id: 6,
          poligon: "M686 518V310H893.09V367.237H918V518H686Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.1,
          transform: null,
          apartmentLayout: 5,
          code: "W3-06",
          typeRoom: 1,
          tower: 1,
          floor: 6,
        },
        {
          id: 7,
          poligon: "M1236 518V310H945.097V367.237H918V518H1236Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 138.4,
          transform: null,
          apartmentLayout: 5,
          code: "W3-07",
          typeRoom: 2,
          tower: 1,
          floor: 6,
        },
      ],
    },
    {
      number: 7,
      layout: layoutw3_7,
      path: [
        {
          id: 1,
          poligon: "M904 761H946V727H971V761H1105V633H904V761Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 136.1,
          transform: null,
          apartmentLayout: 5,
          code: "W3-01",
          typeRoom: 4,
          tower: 1,
          floor: 7,
        },
        {
          id: 2,
          poligon: "M904 633V761H722V726H707V633H904Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 138.1,
          transform: null,
          apartmentLayout: 5,
          code: "W3-02",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 3,
          poligon: "M563 633V761H694.667V726H707V633H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.6,
          transform: null,
          apartmentLayout: 5,
          code: "W3-03",
          typeRoom: 1,
          tower: 1,
          floor: 7,
        },
        {
          id: 4,
          poligon: "M363 633V761H563L563 633H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 142.2,
          transform: null,
          apartmentLayout: 5,
          code: "W3-03A",
          typeRoom: 4,
          tower: 1,
          floor: 7,
        },
        {
          id: 5,
          poligon: "M363 476V604H526L526 476H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.2,
          transform: null,
          apartmentLayout: 5,
          code: "W3-05",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 6,
          poligon: "M563 604V476H691V511H707V604H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.5,
          transform: null,
          apartmentLayout: 5,
          code: "W3-06",
          typeRoom: 1,
          tower: 1,
          floor: 7,
        },
        {
          id: 7,
          poligon: "M905.5 604V476H723V511H707V604H905.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 138.6,
          transform: null,
          apartmentLayout: 5,
          code: "W3-07",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 8,
          poligon: "M1105 319H1063V353H1038V319H904V447H1105V319Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 136.1,
          transform: null,
          apartmentLayout: 5,
          code: "W3-08",
          typeRoom: 4,
          tower: 1,
          floor: 7,
        },
        {
          id: 9,
          poligon: "M1105 447V319H1233V354H1249V447H1105Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 99.6,
          transform: null,
          apartmentLayout: 5,
          code: "W3-09",
          typeRoom: 1,
          tower: 1,
          floor: 7,
        },
        {
          id: 10,
          poligon: "M1355.5 447V319H1265V354H1249V447H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 72.8,
          transform: null,
          apartmentLayout: 5,
          code: "W3-10",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 11,
          poligon: "M1557 447V319H1396V447H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 114.2,
          transform: null,
          apartmentLayout: 5,
          code: "W3-11",
          typeRoom: 2,
          tower: 1,
          floor: 7,
        },
        {
          id: 12,
          poligon: "M1557 604V476H1356V604H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 142.2,
          transform: null,
          apartmentLayout: 5,
          code: "W3-12",
          typeRoom: 4,
          tower: 1,
          floor: 7,
        },
        {
          id: 13,
          poligon: "M1356 476V604H1260.49V569H1249V476H1356Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 73.1,
          transform: null,
          apartmentLayout: 5,
          code: "W3-12A",
          typeRoom: 0,
          tower: 1,
          floor: 7,
        },
        {
          id: 14,
          poligon: "M1104 476V604H1232.5V569H1248.5V476H1104Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 100.1,
          transform: null,
          apartmentLayout: 5,
          code: "W3-12B",
          typeRoom: 1,
          tower: 1,
          floor: 7,
        },
      ],
    },
    {
      number: 23,
      layout: layoutw3_23,
      path: [
        {
          id: 1,
          poligon: "M904 761H946V727H971V761H1105V633H904V761Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 256.33,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL01",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 2,
          poligon: "M904 633V761H722V726H707V633H904Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 257.83,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL02",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 3,
          poligon: "M563 633V761H694.667V726H707V633H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 185.84,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL03",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 4,
          poligon: "M363 633V761H563L563 633H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 258.74,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL03A",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 5,
          poligon: "M363 476V604H526L526 476H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 203.14,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL05",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 6,
          poligon: "M563 604V476H691V511H707V604H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 184.44,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL06",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 7,
          poligon: "M905.5 604V476H723V511H707V604H905.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 258.63,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL07",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 8,
          poligon: "M1105 319H1063V353H1038V319H904V447H1105V319Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 256.13,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL08",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 9,
          poligon: "M1105 447V319H1233V354H1249V447H1105Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 185.74,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL09",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 10,
          poligon: "M1355.5 447V319H1265V354H1249V447H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 73.0,
          transform: null,
          apartmentLayout: 5,
          code: "W3-10",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 11,
          poligon: "M1557 447V319H1396V447H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 203.44,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL11",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 12,
          poligon: "M1557 604V476H1398V604H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 200.14,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL12",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 13,
          poligon: "M1398 476V604H1265V569H1249V476H1398Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 191.94,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL12A",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
        {
          id: 14,
          poligon: "M1104 476V604H1232.5V569H1248.5V476H1104Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 186.14,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL12B",
          typeRoom: 5,
          tower: 1,
          floor: 23,
        },
      ],
    },
    {
      number: 24,
      layout: layoutw3_24,
      path: [
        {
          id: 1,
          poligon: "M904 761H946V727H971V761H1105V633H904V761Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 256.33,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL01",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 2,
          poligon: "M904 633V761H722V726H707V633H904Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 257.83,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL02",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 3,
          poligon: "M563 633V761H694.667V726H707V633H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 185.84,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL03",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 4,
          poligon: "M363 633V761H563L563 633H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 258.74,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL03A",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 5,
          poligon: "M363 476V604H526L526 476H363Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 203.14,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL05",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 6,
          poligon: "M563 604V476H691V511H707V604H563Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 184.44,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL06",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 7,
          poligon: "M905.5 604V476H723V511H707V604H905.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 258.63,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL07",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 8,
          poligon: "M1105 319H1063V353H1038V319H904V447H1105V319Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 256.13,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL08",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 9,
          poligon: "M1105 447V319H1233V354H1249V447H1105Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 185.74,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL09",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 10,
          poligon: "M1355.5 447V319H1265V354H1249V447H1355.5Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 73.0,
          transform: null,
          apartmentLayout: 5,
          code: "W3-10",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 11,
          poligon: "M1557 447V319H1396V447H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 203.44,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL11",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 12,
          poligon: "M1557 604V476H1398V604H1557Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 200.14,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL12",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 13,
          poligon: "M1398 476V604H1265V569H1249V476H1398Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 191.94,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL12A",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
        {
          id: 14,
          poligon: "M1104 476V604H1232.5V569H1248.5V476H1104Z",
          x: null,
          y: null,
          width: null,
          height: null,
          dt: 186.14,
          transform: null,
          apartmentLayout: 5,
          code: "W3-DL12B",
          typeRoom: 5,
          tower: 1,
          floor: 24,
        },
      ],
    },
  ],
];

export const type_rooms = [
  {
    name: "2 Phòng ngủ",
    bedroom: 2,
    wc: 2,
  },
  {
    name: "2 Phòng ngủ + 1",
    bedroom: 2,
    wc: 2,
  },
  {
    name: "3 Phòng ngủ",
    bedroom: 3,
    wc: 3,
  },
  {
    name: "4 Phòng ngủ",
    bedroom: 4,
    wc: 3,
  },
  {
    name: "4 Phòng ngủ DualKey",
    bedroom: 4,
    wc: 3,
  },
  {
    name: "Mẫu A",
    bedroom: null,
    wc: null,
  },
  {
    name: "Penstudio",
    bedroom: null,
    wc: null,
  },
  {
    name: "Penhouse",
    bedroom: null,
    wc: null,
  },
];


export const house360s  = [
  {
    code: "W1-03",
    src: "/file360/wisteria/houses/w1-03/index.html"
  },
  {
    code: "W1-09",
    src: "/file360/wisteria/houses/w1-09/index.html"
  },
  {
    code: "W1-10",
    src: "/file360/wisteria/houses/w1-10/index.html"
  },
  {
    code: "W1-11",
    src: "/file360/wisteria/houses/W1-11/index.html"
  },
  {
    code: "W2-01",
    src: "/file360/wisteria/houses/w2-01/index.html"
  },
  {
    code: "W2-02",
    src: "/file360/wisteria/houses/w2-02/index.html"
  },
]