import { Outlet, useLocation } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import { useState } from "react";
import PopUpGuide from "../components/PopUpGuide";

import tour from "../assets/images/iconNav/tour.png";
import locations from "../assets/images/iconNav/locations.png";
import gallery from "../assets/images/iconNav/gallery.png";
import utils from "../assets/images/iconNav/utils.png";
import subdivisions from "../assets/images/iconNav/subdivisions.png";

import tourHover from "../assets/images/iconNav/tourHover.png";
import locationsHover from "../assets/images/iconNav/locationsHover.png";
import galleryHover from "../assets/images/iconNav/galleryHover.png";
import utilsHover from "../assets/images/iconNav/utilsHover.png";
import subdivisionsHover from "../assets/images/iconNav/subdivisionsHover.png";

import logo from "../assets/images/iconNav/logo.png";

export default function SharedLayout() {
  const menu = [
    {
      label: "Toàn cảnh",
      path: "/tour",
      icon: tour,
      iconHover: tourHover,
    },
    {
      label: "Vị trí",
      path: "/locations",
      icon: locations,
      iconHover: locationsHover,
    },
    {
      label: "Tiện ích",
      path: "/utilities",
      icon: utils,
      iconHover: utilsHover,
    },
    {
      label: "Phân khu",
      path: "/subdivisions",
      icon: subdivisions,
      iconHover: subdivisionsHover,
    },
    {
      label: "Gallery",
      path: "/gallery",
      icon: gallery,
      iconHover: galleryHover,
    },
  ];

  // const [isShowPopUp, setIsShowPopUp] = useState(false);

  const path = useLocation().pathname;

  const handleOpenPopUp = () => {
    if (
      path === "/gallery" ||
      path === "/subdivisions"
    ) {
      return;
    }
    // setIsShowPopUp(true);
  };

  return (
    <div className="h-screen relative">
      {/* {isShowPopUp && <PopUpGuide setIsShowPopUp={setIsShowPopUp} />} */}
      <Outlet />
      <NavBar
        menu={menu}
        logo={logo}
        background={"bg-leafbrownNav bg-white"}
        colorHover={"text-primary"}
        theme="light"
        setIsShowPopUp={handleOpenPopUp}
        backgroundActive={"bg-gradientMain"}
        backgroundHover={"hover:bg-gradientMain"}
        backgroundBorder={"bg-gradientMain"}
      />
    </div>
  );
}
