import { Link, useLocation } from "react-router-dom";

import NavItem from "./NavItem";

export default function NavBar({
  menu,
  logo,
  logoText,
  background,
  colorHover,
  theme,
  setIsShowPopUp,
  backgroundActive,
  backgroundHover,
  backgroundBorder,
}) {
  return (
    <nav
      className={`h-[9vh] max-w-screen w-screen fixed z-[54] bottom-0 left-0 bg-cover bg-center ${background}`}
    >
      {/* <img className="w-full absolute top-0 left-0 object-contain" alt="" src={bgNav} /> */}
      <div className={`w-full h-[2px] ${backgroundBorder}`}></div>
      <div className="grid grid-cols-6 md:grid-cols-7 lg:grid-cols-6 h-full text-white">
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={() => setIsShowPopUp(true)}
        >
          {logo ? (
            <img className="w-14 md:w-20" alt="Logo" src={logo} />
          ) : (
            <div className="logoText font-semibold bg-clip-text text-transparent bg-gradientMain uppercase text-[10px] lg:text-[28px] md:text-[20px]">
              {logoText}
            </div>
          )}
        </div>
        {menu.map((item, index) => (
          <NavItem
            key={index}
            path={item.path}
            label={item.label}
            icon={item.icon}
            iconHover={item.iconHover}
            theme={theme}
            colorHover={colorHover}
            backgroundActive={backgroundActive}
            backgroundHover={backgroundHover}
          />
        ))}
      </div>
    </nav>
  );
}
