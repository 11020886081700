import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import iconDown from "../../assets/images/downIcon.png";

import overviewImage from "../../assets/images/wisteria/mat-bang/w1/overview.jpg";
import overviewImage2 from "../../assets/images/wisteria/mat-bang/overview2.jpg";
import overviewImage3 from "../../assets/images/wisteria/mat-bang/overview3.jpg";
import btnBack from "../../assets/images/wisteria/icon/button-back.png";
import btnBackHover from "../../assets/images/wisteria/icon/button-back_hover.png";

import bg_main from "../../assets/images/wisteria/mat-bang/bg_main.png";
import w1 from "../../assets/images/wisteria/mat-bang/w1.png";
import w2 from "../../assets/images/wisteria/mat-bang/w2.png";
import w3 from "../../assets/images/wisteria/mat-bang/w3.png";

import {
  ground_layout,
  type_rooms,
  house360s,
} from "../../constants/data_ground_wisteria";

const GroundWisteria = () => {
  const [tabActive, setTabActive] = useState({
    parent: null,
    children: null,
  });

  const navigate = useNavigate();

  const tabGround = [
    {
      title: "Tòa W1",
      overview: overviewImage,
      children: [
        {
          title: "Tầng 3",
          parent: 0,
        },
        {
          title: "Tầng 4",
          parent: 0,
        },
        {
          title: "Tầng 5",
          parent: 0,
        },
        {
          title: "Tầng 6",
          parent: 0,
        },
        {
          title: "Tầng 7-22",
          parent: 0,
        },
        {
          title: "Tầng 23",
          parent: 0,
        },
        {
          title: "Tầng 24",
          parent: 0,
        },
      ],
    },
    {
      title: "Tòa W2",
      overview: overviewImage2,
      children: [
        {
          title: "Tầng 3",
          parent: 1,
        },
        {
          title: "Tầng 4",
          parent: 1,
        },
        {
          title: "Tầng 5",
          parent: 1,
        },
        {
          title: "Tầng 6",
          parent: 1,
        },
        {
          title: "Tầng 7-22",
          parent: 1,
        },
        {
          title: "Tầng 23",
          parent: 1,
        },
        {
          title: "Tầng 24",
          parent: 1,
        },
      ],
    },
    {
      title: "Tòa W3",
      overview: overviewImage3,
      children: [
        {
          title: "Tầng 3",
          parent: 2,
        },
        {
          title: "Tầng 4",
          parent: 2,
        },
        {
          title: "Tầng 5",
          parent: 2,
        },
        {
          title: "Tầng 6",
          parent: 2,
        },
        {
          title: "Tầng 7-22",
          parent: 2,
        },
        {
          title: "Tầng 23",
          parent: 2,
        },
        {
          title: "Tầng 24",
          parent: 2,
        },
      ],
    },
  ];

  const [isShowTab, setIsShowTab] = useState(true);
  const [layoutSelected, setLayoutSelected] = useState(0);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isBackGroundMain, setIsBackGroundMain] = useState(true);

  const [isHoveredBtn, setHoveredBtn] = useState(false);

  const [hoveredGround, setHoveredGround] = useState(null);

  const [isShowIframe, setIsShowIframe] = useState(false);

  const handleMouseEnter = (item, event) => {
    // Lấy vị trí chuột khi hover
    const { clientX, clientY } = event;
    setMousePosition({ x: clientX, y: clientY });
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div className="max-h-screen h-screen w-screen overflow-hidden bg-[#50184b] bg-opacity-60">
      <div
        className={`${
          isBackGroundMain && "hidden md:flex"
        } mb-8 flex justify-center items-center fixed z-40 top-4 left-1/2 -translate-x-1/2 w-[65%] lg:w-[35%]`}
      >
        <div
          className={`w-full grid grid-cols-3 md:grid-cols-3 gap-1 md:gap-2 p-1 md:p-2 bg-[#50184b] bg-opacity-60 backdrop-blur-sm border border-white
                        rounded-xl md:rounded-2xl`}
        >
          {tabGround.map((house, index) => (
            <div
              key={index}
              className={`flex items-center justify-center gap-2 md:gap-4 text-center text-[10px] md:text-base
                ${
                  tabActive.parent === index
                    ? "text-primary bg-gradientNavWisteria"
                    : "text-sub bg-white"
                }
                    hover:text-primary hover:bg-gradientNavWisteria hover:shadow-2xl 
                      py-2 rounded-lg md:rounded-xl font-bold uppercase cursor-pointer relative group`}
              onClick={() => {
                setTabActive({
                  parent: index,
                  children: 0,
                });
                setLayoutSelected(0);
                setIsBackGroundMain(false);
              }}
            >
              {house.title}
              <img src={iconDown} alt="iconDown" className="w-2 md:w-3" />

              <div
                className="hidden absolute z-[41] top-5 md:top-10 left-0 w-full group-hover:block"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="h-4 w-full"></div>
                <div
                  className="flex flex-col gap-1 md:gap-2 w-full p-1 md:p-2 bg-[#50184b] bg-opacity-60
                  backdrop-blur-md border border-white rounded-xl"
                >
                  {house.children.map((item, index) => (
                    <div
                      key={index}
                      className={`text-center ${
                        tabActive.children === index &&
                        tabActive.parent === item.parent
                          ? "text-primary bg-gradientNavWisteria"
                          : "text-sub bg-white"
                      }
                    hover:text-primary hover:bg-gradientNavWisteria hover:shadow-2xl 
                      py-2 rounded-lg md:rounded-xl font-bold uppercase cursor-pointer`}
                      onClick={() => {
                        console.log({
                          parent: item.parent,
                          children: index,
                        });
                        setTabActive({
                          parent: item.parent,
                          children: index,
                        });
                        setLayoutSelected(0);
                        setIsBackGroundMain(false);
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isBackGroundMain ? (
        <div className="w-[240%] left-[55%] -translate-x-1/2 md:left-0 md:translate-x-0 md:w-full h-full overflow-hidden relative flex items-center">
          <img src={bg_main} alt="bg-main" className="w-full absolute" />
          <svg
            className="w-full absolute"
            viewBox="0 0 1920 1080"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M707.93 525.756L708 525.638V525.5V465.635L799.287 309H863.512L869.497 547.386L770.054 743.274L770 743.38V743.5V852.5H688.485L680.004 572.631L707.93 525.756Z"
              className={`fill-[#FE6C05] hover:opacity-50 ${
                hoveredGround === 2 ? "opacity-50" : "opacity-0"
              } cursor-pointer`}
              onClick={() => {
                setIsBackGroundMain(false);
                setTabActive({
                  parent: 2,
                  children: 0,
                });
                setLayoutSelected(0);
              }}
            />
            <path
              d="M1005 223V221.5H1108.78L1113.13 226.334L1113.28 226.5H1113.5H1124.71L1133.5 241.639L1138.5 514.879L1104.45 578.603L1097 314.986L1096.99 314.5H1096.5H1031H1030.7L1030.56 314.764L971.701 424.5H938H937.701L937.559 424.764L873.201 545H870.488L864.5 307.987L864.488 307.5H864H822.5V269.636L842.369 235.858L865.144 221.5H910.833L916.7 225.9L916.833 226H917H961H961.093L961.179 225.967L967.593 223.5H1004.5H1005V223Z"
              className={`fill-[#55D4F5] hover:opacity-50 ${
                hoveredGround === 0 ? "opacity-50" : "opacity-0"
              } cursor-pointer`}
              onClick={() => {
                setIsBackGroundMain(false);
                setTabActive({
                  parent: 0,
                  children: 0,
                });
                setLayoutSelected(0);
              }}
            />
            <path
              d="M972.5 426H972.8L972.941 425.736L1031.8 315.5H1096.01L1103 580.897L1067.68 657.5H1026.5H1026.18L1026.05 657.786L989.548 734.786L989.497 734.895L989.5 735.015L991.991 817.226L933.858 853H866.989L861.003 572.619L938.302 426H972.5Z"
              className={`fill-[#50184b] hover:opacity-50 ${
                hoveredGround === 1 ? "opacity-50" : "opacity-0"
              } cursor-pointer`}
              onClick={() => {
                setIsBackGroundMain(false);
                setTabActive({
                  parent: 1,
                  children: 0,
                });
                setLayoutSelected(0);
              }}
            />
            <g
              filter="url(#filter2_b_835_8149)"
              onClick={() => {
                setIsBackGroundMain(false);
                setTabActive({
                  parent: 0,
                  children: 0,
                });
                setLayoutSelected(0);
              }}
              onMouseEnter={() => setHoveredGround(0)}
              onMouseLeave={() => setHoveredGround(null)}
              className="cursor-pointer"
            >
              <path
                d="M704.5 184.5C704.5 174.835 712.335 167 722 167H808C817.665 167 825.5 174.835 825.5 184.5V184.5C825.5 194.165 817.665 202 808 202H722C712.335 202 704.5 194.165 704.5 184.5V184.5Z"
                fill="#50184B"
              />
              <path
                d="M722 167.5H808C817.389 167.5 825 175.111 825 184.5C825 193.889 817.389 201.5 808 201.5H722C712.611 201.5 705 193.889 705 184.5C705 175.111 712.611 167.5 722 167.5Z"
                stroke="url(#paint6_linear_835_8149)"
              />
              <path
                d="M736.731 190V180.624H733.179V178.8H742.267V180.624H738.715V190H736.731ZM749.698 190.192C746.258 190.192 743.858 187.584 743.858 184.432V184.4C743.858 181.264 746.29 178.608 749.73 178.608C753.17 178.608 755.57 181.216 755.57 184.368V184.4C755.57 187.536 753.138 190.192 749.698 190.192ZM749.73 188.384C751.954 188.384 753.506 186.608 753.506 184.432V184.4C753.506 182.224 751.938 180.416 749.698 180.416C747.474 180.416 745.922 182.192 745.922 184.368V184.4C745.922 186.576 747.49 188.384 749.73 188.384ZM749.714 177.712L747.746 175.872L749.506 175.088L751.17 177.712H749.714ZM756.3 190L761.228 178.72H763.052L767.98 190H765.9L764.764 187.296H759.468L758.316 190H756.3ZM760.188 185.552H764.044L762.108 181.072L760.188 185.552ZM777.738 190.08L773.834 178.8H775.962L778.634 187.104L781.386 178.768H783.05L785.802 187.104L788.474 178.8H790.538L786.634 190.08H784.938L782.186 182.032L779.434 190.08H777.738ZM793.965 190V180.72L791.949 181.28L791.533 179.68L794.541 178.72H795.917V190H793.965Z"
                fill="url(#paint7_linear_835_8149)"
              />
            </g>
            <path
              d="M837.499 258L833.875 253.506L831.795 258.892L837.499 258ZM765 230L764.5 230L764.5 230.343L764.82 230.466L765 230ZM764.82 230.466L833.121 256.845L833.482 255.912L765.18 229.534L764.82 230.466ZM764.5 202L764.5 216L765.5 216L765.5 202L764.5 202ZM764.5 216L764.5 230L765.5 230L765.5 216L764.5 216Z"
              fill="url(#paint8_linear_835_8149)"
            />
            <g
              filter="url(#filter0_b_835_8149)"
              onClick={() => {
                setIsBackGroundMain(false);
                setTabActive({
                  parent: 1,
                  children: 0,
                });
                setLayoutSelected(0);
              }}
              onMouseEnter={() => setHoveredGround(1)}
              onMouseLeave={() => setHoveredGround(null)}
              className="cursor-pointer"
            >
              <path
                d="M1038.5 420.5C1038.5 410.835 1046.34 403 1056 403H1146C1155.66 403 1163.5 410.835 1163.5 420.5V420.5C1163.5 430.165 1155.66 438 1146 438H1056C1046.34 438 1038.5 430.165 1038.5 420.5V420.5Z"
                fill="#50184B"
              />
              <path
                d="M1056 403.5H1146C1155.39 403.5 1163 411.111 1163 420.5C1163 429.889 1155.39 437.5 1146 437.5H1056C1046.61 437.5 1039 429.889 1039 420.5C1039 411.111 1046.61 403.5 1056 403.5Z"
                stroke="url(#paint0_linear_835_8149)"
              />
              <path
                d="M1071 426V416.624H1067.44V414.8H1076.53V416.624H1072.98V426H1071ZM1083.96 426.192C1080.52 426.192 1078.12 423.584 1078.12 420.432V420.4C1078.12 417.264 1080.56 414.608 1084 414.608C1087.44 414.608 1089.84 417.216 1089.84 420.368V420.4C1089.84 423.536 1087.4 426.192 1083.96 426.192ZM1084 424.384C1086.22 424.384 1087.77 422.608 1087.77 420.432V420.4C1087.77 418.224 1086.2 416.416 1083.96 416.416C1081.74 416.416 1080.19 418.192 1080.19 420.368V420.4C1080.19 422.576 1081.76 424.384 1084 424.384ZM1083.98 413.712L1082.01 411.872L1083.77 411.088L1085.44 413.712H1083.98ZM1090.57 426L1095.49 414.72H1097.32L1102.25 426H1100.17L1099.03 423.296H1093.73L1092.58 426H1090.57ZM1094.45 421.552H1098.31L1096.37 417.072L1094.45 421.552ZM1112 426.08L1108.1 414.8H1110.23L1112.9 423.104L1115.65 414.768H1117.32L1120.07 423.104L1122.74 414.8H1124.8L1120.9 426.08H1119.2L1116.45 418.032L1113.7 426.08H1112ZM1126.17 426V424.448L1130.02 421.168C1131.56 419.888 1132.12 419.152 1132.12 418.144C1132.12 417.04 1131.33 416.4 1130.31 416.4C1129.29 416.4 1128.6 416.944 1127.72 418.08L1126.33 416.992C1127.4 415.488 1128.47 414.64 1130.45 414.64C1132.63 414.64 1134.15 415.984 1134.15 417.984V418.016C1134.15 419.792 1133.21 420.72 1131.24 422.352L1128.93 424.272H1134.26V426H1126.17Z"
                fill="url(#paint1_linear_835_8149)"
              />
            </g>
            <path
              d="M1028.5 494L1032.12 489.506L1034.2 494.892L1028.5 494ZM1101 466L1101.5 466L1101.5 466.343L1101.18 466.466L1101 466ZM1101.18 466.466L1032.88 492.845L1032.52 491.912L1100.82 465.534L1101.18 466.466ZM1101.5 438L1101.5 452L1100.5 452L1100.5 438L1101.5 438ZM1101.5 452L1101.5 466L1100.5 466L1100.5 452L1101.5 452Z"
              fill="url(#paint2_linear_835_8149)"
            />
            <g
              filter="url(#filter1_b_835_8149)"
              onClick={() => {
                setIsBackGroundMain(false);
                setTabActive({
                  parent: 2,
                  children: 0,
                });
                setLayoutSelected(0);
              }}
              onMouseEnter={() => setHoveredGround(2)}
              onMouseLeave={() => setHoveredGround(null)}
              className="cursor-pointer"
            >
              <path
                d="M583.5 409.5C583.5 399.835 591.335 392 601 392H691C700.665 392 708.5 399.835 708.5 409.5V409.5C708.5 419.165 700.665 427 691 427H601C591.335 427 583.5 419.165 583.5 409.5V409.5Z"
                fill="#50184B"
              />
              <path
                d="M601 392.5H691C700.389 392.5 708 400.111 708 409.5C708 418.889 700.389 426.5 691 426.5H601C591.611 426.5 584 418.889 584 409.5C584 400.111 591.611 392.5 601 392.5Z"
                stroke="url(#paint3_linear_835_8149)"
              />
              <path
                d="M615.958 415V405.624H612.406V403.8H621.494V405.624H617.942V415H615.958ZM628.925 415.192C625.485 415.192 623.085 412.584 623.085 409.432V409.4C623.085 406.264 625.517 403.608 628.957 403.608C632.397 403.608 634.797 406.216 634.797 409.368V409.4C634.797 412.536 632.365 415.192 628.925 415.192ZM628.957 413.384C631.181 413.384 632.733 411.608 632.733 409.432V409.4C632.733 407.224 631.165 405.416 628.925 405.416C626.701 405.416 625.149 407.192 625.149 409.368V409.4C625.149 411.576 626.717 413.384 628.957 413.384ZM628.941 402.712L626.973 400.872L628.733 400.088L630.397 402.712H628.941ZM635.527 415L640.455 403.72H642.279L647.207 415H645.127L643.991 412.296H638.695L637.543 415H635.527ZM639.415 410.552H643.271L641.335 406.072L639.415 410.552ZM656.964 415.08L653.06 403.8H655.188L657.86 412.104L660.612 403.768H662.276L665.028 412.104L667.7 403.8H669.764L665.86 415.08H664.164L661.412 407.032L658.66 415.08H656.964ZM675.32 415.192C673.352 415.192 671.992 414.376 671.032 413.224L672.408 411.944C673.224 412.904 674.088 413.416 675.352 413.416C676.456 413.416 677.288 412.744 677.288 411.72V411.688C677.288 410.584 676.296 409.96 674.712 409.96H673.8L673.48 408.728L676.504 405.512H671.656V403.8H679.064V405.272L675.944 408.472C677.64 408.68 679.24 409.544 679.24 411.624V411.656C679.24 413.72 677.64 415.192 675.32 415.192Z"
                fill="url(#paint4_linear_835_8149)"
              />
            </g>
            <path
              d="M718.499 483L714.875 478.506L712.795 483.892L718.499 483ZM646 455L645.5 455L645.5 455.343L645.82 455.466L646 455ZM645.82 455.466L714.121 481.845L714.482 480.912L646.18 454.534L645.82 455.466ZM645.5 427L645.5 441L646.5 441L646.5 427L645.5 427ZM645.5 441L645.5 455L646.5 455L646.5 441L645.5 441Z"
              fill="url(#paint5_linear_835_8149)"
            />
            <defs>
              <filter
                id="filter0_b_835_8149"
                x="1025.9"
                y="390.4"
                width="150.2"
                height="60.2"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.3" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_835_8149"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_835_8149"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_b_835_8149"
                x="570.9"
                y="379.4"
                width="150.2"
                height="60.2"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.3" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_835_8149"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_835_8149"
                  result="shape"
                />
              </filter>
              <filter
                id="filter2_b_835_8149"
                x="691.9"
                y="154.4"
                width="146.2"
                height="60.2"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.3" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_835_8149"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_835_8149"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_835_8149"
                x1="997.582"
                y1="389.052"
                x2="1163.7"
                y2="770.23"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_835_8149"
                x1="1043.91"
                y1="410.616"
                x2="1077.23"
                y2="544.901"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_835_8149"
                x1="999.608"
                y1="419.669"
                x2="1185.27"
                y2="793.793"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_835_8149"
                x1="542.582"
                y1="378.052"
                x2="708.702"
                y2="759.23"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_835_8149"
                x1="588.913"
                y1="399.616"
                x2="622.233"
                y2="533.901"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_835_8149"
                x1="747.392"
                y1="408.669"
                x2="561.728"
                y2="782.793"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_835_8149"
                x1="664.891"
                y1="153.052"
                x2="834.682"
                y2="530.184"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_835_8149"
                x1="711.222"
                y1="174.616"
                x2="746.335"
                y2="307.92"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_835_8149"
                x1="866.392"
                y1="183.669"
                x2="680.728"
                y2="557.793"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#C2AB87" />
                <stop offset="0.15" stop-color="#FFEFC6" />
                <stop offset="0.41" stop-color="#B08F58" />
                <stop offset="0.68" stop-color="#FFEFC6" />
                <stop offset="1" stop-color="#C2AB87" />
              </linearGradient>
            </defs>
          </svg>

          {/* <img src={w2} alt="w2" className=" absolute top-[32%] right-[40%]" />
          <img src={w3} alt="w3" className=" absolute top-[32%] left-[30%]" /> */}
        </div>
      ) : (
        <>
          <div className="md:flex w-full h-full bg-[#f8ecf9]">
            <div
              className="w-full h-full overflow-hidden relative flex items-center"
              // onClick={() => setIsShowTab(!isShowTab)}
            >
              <img
                src={ground_layout[tabActive.parent][tabActive.children].layout}
                alt="fullmap"
                className="absolute w-full"
              />
              <svg
                className="absolute w-full"
                viewBox="0 0 1920 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* svg */}
                {ground_layout[tabActive.parent][tabActive.children].path.map(
                  (item, index) => (
                    <>
                      {item.poligon ? (
                        <path
                          key={item.id}
                          d={item.poligon}
                          className={`${
                            index === layoutSelected
                              ? "fill-[#50184b] opacity-50"
                              : "fill-transparent"
                          }  hover:fill-[#50184b] hover:opacity-50 stroke-[#50184b] cursor-pointer`}
                          onMouseEnter={(e) => handleMouseEnter(item, e)}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => {
                            if (index !== layoutSelected) {
                              setLayoutSelected(index);
                              setIsShowTab(true)
                            }
                          }}
                        />
                      ) : (
                        <rect
                          key={item.id}
                          x={item.x}
                          y={item.y}
                          transform={item.transform}
                          width={item.width}
                          height={item.height}
                          className={`${
                            index === layoutSelected
                              ? "fill-[#50184b] opacity-50"
                              : "fill-transparent"
                          } hover:fill-[#50184b] hover:opacity-50 stroke-[#50184b] cursor-pointer`}
                          onMouseEnter={(e) => handleMouseEnter(item, e)}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => {
                            console.log(`ID: ${item.id}`);

                            if (index !== layoutSelected) {
                              setLayoutSelected(index);
                              setIsShowTab(true)
                            }
                          }}
                        />
                      )}
                    </>
                  )
                )}
              </svg>
            </div>

            {/* hover */}
            {hoveredItem && (
              <div
                className="absolute bg-[#50184b] border border-[#FFEFC6] p-4 shadow z-50 rounded-xl hidden md:block"
                style={{
                  top: mousePosition.y - 200, // Hiển thị popup cách chuột 10px
                  left: mousePosition.x - 130,
                }}
              >
                <div
                  className="w-full py-2 px-28 uppercase bg-gradientNavWisteria text-center rounded-xl text-[#50184b]
              font-bold cursor-pointer hover:opacity-90 mb-2 text-lg
            "
                >
                  {hoveredItem.code}
                </div>
                <p className="uppercase text-white font-semibold mb-3">
                  Diện tích: {hoveredItem.dt} m²
                </p>
                <p className="uppercase text-white">*Click để xem chi tiết</p>
              </div>
            )}

            {/* TAB PC */}
            {isShowTab && (
            <div
              className={`h-full bg-leafwhite bg-[#50184b] bg-cover bg-center 
                      z-20 md:relative md:w-[40vw] lg:w-[30vw] px-8 transition-all
                      md:flex hidden flex-col justify-between pt-[3vh] pb-[12vh]`}
            >
              <div className="text-white font-bold">
                {/* title */}
                <div className=" uppercase text-center mb-5 text-xl">
                  Căn hộ{" "}
                  {
                    type_rooms[
                      ground_layout[tabActive.parent][tabActive.children].path[
                        layoutSelected
                      ].typeRoom
                    ].name
                  }
                </div>
                <img
                  src={tabGround[tabActive.parent].overview}
                  alt="overview"
                  className="rounded-3xl w-full object-contain mb-4"
                />
                <div className="w-full h-[2px] bg-gradientNavWisteria mb-4"></div>
                <div className="flex justify-between font-medium mb-3">
                  <div className="uppercase">Số tầng</div>
                  <div className="uppercase">
                    {ground_layout[tabActive.parent][tabActive.children].path[
                      layoutSelected
                    ].floor === 7
                      ? "7 - 22"
                      : ground_layout[tabActive.parent][tabActive.children]
                          .path[layoutSelected].floor}
                  </div>
                </div>
                {type_rooms[
                  ground_layout[tabActive.parent][tabActive.children].path[
                    layoutSelected
                  ].typeRoom
                ].bedroom && (
                  <div className="flex justify-between font-medium mb-3">
                    <div className="uppercase">Số phòng ngủ</div>
                    <div className="uppercase">
                      {
                        type_rooms[
                          ground_layout[tabActive.parent][tabActive.children]
                            .path[layoutSelected].typeRoom
                        ].bedroom
                      }
                    </div>
                  </div>
                )}

                {type_rooms[
                  ground_layout[tabActive.parent][tabActive.children].path[
                    layoutSelected
                  ].typeRoom
                ].wc && (
                  <div className="flex justify-between font-medium mb-3">
                    <div className="uppercase">Số phòng WC</div>
                    <div className="uppercase">
                      {
                        type_rooms[
                          ground_layout[tabActive.parent][tabActive.children]
                            .path[layoutSelected].typeRoom
                        ].wc
                      }
                    </div>
                  </div>
                )}

                <div className="flex justify-between font-medium mb-3">
                  <div className="uppercase">Diện tích căn hộ</div>
                  <div className="">
                    {
                      ground_layout[tabActive.parent][tabActive.children].path[
                        layoutSelected
                      ].dt
                    }{" "}
                    m²
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="w-full py-3 uppercase bg-gradientNavWisteria text-center rounded-xl text-[#50184b]
              font-bold cursor-pointer hover:opacity-90 mb-4
            "
                >
                  Xem mặt bằng tầng
                </div>

                {house360s.some(
                  (item) =>
                    item.code ===
                    ground_layout[tabActive.parent][tabActive.children].path[
                      layoutSelected
                    ].code
                ) && (
                  <div
                    className="w-full py-3 uppercase bg-gradientNavWisteria text-center rounded-xl text-[#50184b]
              font-bold cursor-pointer hover:opacity-90"
                    onClick={() => {
                      setIsShowIframe(true);
                    }}
                  >
                    Xem 360°
                  </div>
                )}
              </div>
              <img
                src={!isHoveredBtn ? btnBack : btnBackHover}
                onMouseEnter={() => setHoveredBtn(true)}
                onMouseLeave={() => setHoveredBtn(false)}
                alt="btnBack"
                className="fixed z-40 top-2 left-2 md:top-4 md:left-4 cursor-pointer w-10 h-10 md:w-14 md:h-14 transition md:hidden"
                onClick={() => {
                  setIsShowTab(false);
                  setLayoutSelected(null);
                }}
              />
            </div>
              )}
            
            {/* TAB MOBILE */}
            <div
              className={`h-full bg-leafwhite bg-[#50184b] bg-cover bg-center 
              ${
                isShowTab
                  ? "w-screen fixed top-0 left-0 z-50 md:z-20 md:relative md:w-[40vw] lg:w-[30vw] px-8"
                  : "w-0 px-0 fixed top-0 left-0 z-50 md:z-20 md:relative"
              } transition-all
            flex md:hidden flex-col justify-between pt-[3vh] pb-[12vh]`}
            >
              {isShowTab && (
                <>
                  <div className="text-white font-bold">
                    {/* title */}
                    <div className=" uppercase text-center mb-5 md:text-xl">
                      Căn hộ{" "}
                      {
                        type_rooms[
                          ground_layout[tabActive.parent][tabActive.children]
                            .path[layoutSelected].typeRoom
                        ].name
                      }
                    </div>
                    <img
                      src={tabGround[tabActive.parent].overview}
                      alt="overview"
                      className="rounded-3xl w-full object-contain mb-4"
                    />
                    <div className="w-full h-[2px] bg-gradientNavWisteria mb-4"></div>
                    <div className="flex justify-between font-medium mb-3">
                      <div className="uppercase text-sm md:text-base">Số tầng</div>
                      <div className="uppercase text-sm md:text-base">
                        {ground_layout[tabActive.parent][tabActive.children]
                          .path[layoutSelected].floor === 7
                          ? "7 - 22"
                          : ground_layout[tabActive.parent][tabActive.children]
                              .path[layoutSelected].floor}
                      </div>
                    </div>
                    {type_rooms[
                      ground_layout[tabActive.parent][tabActive.children].path[
                        layoutSelected
                      ].typeRoom
                    ].bedroom && (
                      <div className="flex justify-between font-medium mb-3">
                        <div className="uppercase text-sm md:text-base">Số phòng ngủ</div>
                        <div className="uppercase text-sm md:text-base">
                          {
                            type_rooms[
                              ground_layout[tabActive.parent][
                                tabActive.children
                              ].path[layoutSelected].typeRoom
                            ].bedroom
                          }
                        </div>
                      </div>
                    )}

                    {type_rooms[
                      ground_layout[tabActive.parent][tabActive.children].path[
                        layoutSelected
                      ].typeRoom
                    ].wc && (
                      <div className="flex justify-between font-medium mb-3">
                        <div className="uppercase text-sm md:text-base">Số phòng WC</div>
                        <div className="uppercase text-sm md:text-base">
                          {
                            type_rooms[
                              ground_layout[tabActive.parent][
                                tabActive.children
                              ].path[layoutSelected].typeRoom
                            ].wc
                          }
                        </div>
                      </div>
                    )}

                    <div className="flex justify-between font-medium mb-3">
                      <div className="uppercase text-sm md:text-base">Diện tích căn hộ</div>
                      <div className="">
                        {
                          ground_layout[tabActive.parent][tabActive.children]
                            .path[layoutSelected].dt
                        }{" "}
                        m²
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="w-full py-3 uppercase text-sm md:text-base bg-gradientNavWisteria text-center rounded-xl text-[#50184b]
              font-bold cursor-pointer hover:opacity-90 mb-4
            "
                    >
                      Xem mặt bằng tầng
                    </div>

                    {house360s.some(
                      (item) =>
                        item.code ===
                        ground_layout[tabActive.parent][tabActive.children]
                          .path[layoutSelected].code
                    ) && (
                      <div
                        className="w-full py-3 uppercase bg-gradientNavWisteria text-center rounded-xl text-[#50184b]
              font-bold cursor-pointer hover:opacity-90"
                        onClick={() => {
                          setIsShowIframe(true);
                        }}
                      >
                        Xem 360°
                      </div>
                    )}
                  </div>
                  <img
                    src={!isHoveredBtn ? btnBack : btnBackHover}
                    onMouseEnter={() => setHoveredBtn(true)}
                    onMouseLeave={() => setHoveredBtn(false)}
                    alt="btnBack"
                    className="fixed z-40 top-2 left-2 md:top-4 md:left-4 cursor-pointer w-10 h-10 md:w-14 md:h-14 transition md:hidden"
                    onClick={() => {
                      setIsShowTab(false);
                      setLayoutSelected(null);
                    }}
                  />
                </>
              )}
            </div>

            <img
              src={!isHoveredBtn ? btnBack : btnBackHover}
              onMouseEnter={() => setHoveredBtn(true)}
              onMouseLeave={() => setHoveredBtn(false)}
              alt="btnBack"
              className="fixed z-[35] top-2 left-2 md:top-4 md:left-4 cursor-pointer w-10 h-10 md:w-14 md:h-14 transition"
              onClick={() => {
                setIsBackGroundMain(true);
              }}
            />
          </div>
          {isShowIframe && (
            <>
              <img
                src={!isHoveredBtn ? btnBack : btnBackHover}
                onMouseEnter={() => setHoveredBtn(true)}
                onMouseLeave={() => setHoveredBtn(false)}
                alt="btnBack"
                className="fixed z-50 top-2 left-2 md:top-4 md:left-4 cursor-pointer w-10 h-10 md:w-14 md:h-14 transition"
                onClick={() => {
                  setIsShowIframe(false);
                }}
              />
              <div className="fixed top-0 left-0 h-screen w-full z-[25]">
                <div className="h-screen relative">
                  <iframe
                    src={
                      house360s.find(
                        (item) =>
                          item.code ===
                          ground_layout[tabActive.parent][tabActive.children]
                            .path[layoutSelected].code
                      ).src
                    }
                    title="can ho"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GroundWisteria;
