import React from "react";
import Location from "../LocationTemplate";

const UtilBinhMinh = () => {
  return (
    <>
      <Location />
    </>
  );
};

export default UtilBinhMinh;
