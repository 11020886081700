import React from "react";
import btnBack from "../assets/images/btnBack.png";


const PopUpMedia = ({ type, src, setIsShowPopUp }) => {
  console.log("src", src);
  return (
    <div className="fixed z-[55] w-full h-screen top-0 left-0 bg-black flex items-center">
      <img
        src={btnBack}
        alt="btnBack"
        className="absolute z-30 top-2 left-2 md:top-4 md:left-4 cursor-pointer w-14 h-14 md:w-20 md:h-20 transition"
        onClick={() => {
          setIsShowPopUp(false);
        }}
      />
      {type === "video" && (
        <video width="400" controls className="w-full h-full" autoPlay>
          <source src={src} type="video/mp4" />
        </video>
      )}
      {type === "image" && (
        <img
          src={src}
          alt="imagePopUp"
          className="w-full object-contain"
        />
      )}
    </div>
  );
};

export default PopUpMedia;
