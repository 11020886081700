import React, { useState } from "react";

const TabItem = ({ tabActive, item, setTabActive, isBlur }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => setTabActive(item)}
      className={`flex items-center justify-center text-center text-[10px] md:text-base
        ${
          tabActive.title === item.title || isHover
            ? "text-primary bg-gradientMain shadow-2xl"
            : `text-sub ${
                isBlur
                  ? "bg-[#fff] bg-opacity-20 backdrop-blur-sm"
                  : "bg-white"
              }`
        } p-2 rounded-md md:rounded-xl font-bold uppercase cursor-pointer`}
    >
      {item.title}
    </div>
  );
};

export default TabItem;
