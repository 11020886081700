import React, { useState } from "react";
import PopUpMedia from "../components/PopUpMedia";
import Tab from "../components/Tab/Tab";
import Video from "../components/Video";

import videoTest from "../assets/videos/videotest.mp4";

import bgTest from "../assets/images/bgLoading.png";
import bgTest2 from "../assets/images/bgTest2.png";
import bgTest3 from "../assets/images/bgTest3.png";
import bgTest4 from "../assets/images/thumb_wisteria.jpg";

import video1 from "../assets/videos/videotest1.mp4";
import video2 from "../assets/videos/videotest2.mp4";
import video3 from "../assets/videos/videotest3.mp4";
import video4 from "../assets/videos/videotest4.mp4";

import util1 from "../assets/images/gallery/tienich/util1.jpg";
import util2 from "../assets/images/gallery/tienich/util2.jpg";
import util6 from "../assets/images/gallery/tienich/util6.jpg";
import util7 from "../assets/images/gallery/tienich/util7.jpg";
import util9 from "../assets/images/gallery/tienich/util9.jpg";
import util10 from "../assets/images/gallery/tienich/util10.jpg";
import util11 from "../assets/images/gallery/tienich/util11.jpg";
import util13 from "../assets/images/gallery/tienich/util13.jpg";
import util5 from "../assets/images/gallery/tienich/util5.png";
import util8 from "../assets/images/gallery/tienich/util8.png";

const Gallery = () => {
  const tabs = [
    {
      title: "Văn phòng bán hàng",
      items: [
        {
          thumb: bgTest,
          src: bgTest,
          type: "image",
        },
        {
          thumb: bgTest2,
          src: bgTest2,
          type: "image",
        },
        {
          thumb: bgTest3,
          src: bgTest3,
          type: "image",
        },
        {
          thumb: bgTest4,
          src: bgTest4,
          type: "image",
        },
      ],
    },
    {
      title: "Tiện ích",
      items: [
        {
          thumb: util1,
          src: util1,
          type: "image",
        },
        {
          thumb: util2,
          src: util2,
          type: "image",
        },
        {
          thumb: util6,
          src: util6,
          type: "image",
        },
        {
          thumb: util7,
          src: util7,
          type: "image",
        },
        {
          thumb: util9,
          src: util9,
          type: "image",
        },
        {
          thumb: util10,
          src: util10,
          type: "image",
        },
        {
          thumb: util11,
          src: util11,
          type: "image",
        },
        {
          thumb: util13,
          src: util13,
          type: "image",
        },
        {
          thumb: util5,
          src: util5,
          type: "image",
        },
        {
          thumb: util8,
          src: util8,
          type: "image",
        },
      ],
    },
    {
      title: "Video",
      items: [
        {
          thumb: bgTest,
          src: video1,
          type: "video",
        },
        {
          thumb: bgTest2,
          src: video2,
          type: "video",
        },
        {
          thumb: bgTest3,
          src: video3,
          type: "video",
        },
        {
          thumb: bgTest4,
          src: video4,
          type: "video",
        },
      ],
    },
    {
      title: "Nhà mẫu",
      items: [
        {
          thumb: bgTest,
          src: bgTest,
          type: "image",
        },
        {
          thumb: bgTest2,
          src: bgTest2,
          type: "image",
        },
        {
          thumb: bgTest3,
          src: bgTest3,
          type: "image",
        },
        {
          thumb: bgTest4,
          src: bgTest4,
          type: "image",
        },
      ],
    },
  ];

  const [tabActive, setTabActive] = useState(tabs[2]);
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [currentMedia, setCurrentMedia] = useState();
  return (
    <div className="bg-leafwhite bg-[#d7b6a0] bg-cover bg-fixed bg-center min-h-screen px-6 pt-4">
      <Tab
        tabs={tabs}
        tabActive={tabActive}
        setTabActive={setTabActive}
        cols={tabs.length}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-h-[80vh] overflow-scroll px-2 pb-24 md:pb-8 pt-2">
        {tabActive.items.map((item, index) => {
          return (
            <>
              {item.type === "video" ? (
                <Video
                  item={item}
                  index={index}
                  tabActive={tabActive}
                  setIsShowPopUp={setIsShowPopUp}
                  setCurrentMedia={setCurrentMedia}
                />
              ) : (
                <div
                  className="relative bg-[#282f39] rounded-lg cursor-pointer transition-all group hover:scale-[1.02]"
                  onClick={() => {
                    setIsShowPopUp(true);
                    setCurrentMedia(item);
                  }}
                >
                  <img
                    src={item.thumb}
                    alt={`${tabActive.title}${index}`}
                    className="w-full h-[300px] object-cover rounded-lg"
                  />
                </div>
              )}
            </>
          );
        })}
      </div>
      {isShowPopUp && (
        <PopUpMedia
          type={currentMedia.type}
          src={currentMedia.src}
          setIsShowPopUp={setIsShowPopUp}
        />
      )}
    </div>
  );
};

export default Gallery;
