// import { useState, useRef, useEffect, useCallback } from "react";
// import "../App.css";

// const MapZD = ({ map, imageWidth, imageHeight }) => {
//   const mapWrapRef = useRef(null);
//   const mapRef = useRef(null);
//   const [isDragging, setIsDragging] = useState(false);
//   const [translateX, setTranslateX] = useState(0);
//   const [translateY, setTranslateY] = useState(0);
//   const [scale, setScale] = useState(1);
//   const [initialScale, setInitialScale] = useState(1);
//   const startXRef = useRef(0);
//   const startYRef = useRef(0);

//   useEffect(() => {
//     const mapWrap = mapWrapRef.current;
//     const map = mapRef.current;

//     if (!mapWrap || !map) return;

//     const H = mapWrap.clientWidth / (imageWidth / imageHeight);
//     const viewH = mapWrap.clientHeight;

//     const W = mapWrap.clientHeight / (imageHeight / imageWidth);
//     const viewW = mapWrap.clientWidth;

//     if (H / viewH) {
//       const rateH = (Math.ceil((1 / (H / viewH)) * 100) / 100).toFixed(2);
//       const initialScaleValue = Number(rateH);
//       setInitialScale(initialScaleValue);
//       setScale(initialScaleValue);
//       map.style.transform = `scale(${initialScaleValue})`;
//     } else {
//       const rateW = (Math.ceil((1 / (H / viewH)) * 100) / 100).toFixed(2);
//       const initialScaleValue = Number(rateW);
//       setInitialScale(initialScaleValue);
//       setScale(initialScaleValue);
//       map.style.transform = `scale(${initialScaleValue})`;
//     }
//   }, [imageWidth, imageHeight]);

//   const handleStart = useCallback(
//     (e) => {
//       setIsDragging(true);
//       const clientX = e.touches ? e.touches[0].clientX : e.clientX;
//       const clientY = e.touches ? e.touches[0].clientY : e.clientY;
//       startXRef.current = clientX - translateX;
//       startYRef.current = clientY - translateY;
//       mapRef.current.style.cursor = "grabbing";
//     },
//     [translateX, translateY]
//   );

//   const handleMove = useCallback(
//     (e) => {
//       if (!isDragging) return;
//       const clientX = e.touches ? e.touches[0].clientX : e.clientX;
//       const clientY = e.touches ? e.touches[0].clientY : e.clientY;

//       const newX = clientX - startXRef.current;
//       const newY = clientY - startYRef.current;

//       const mapWrap = mapWrapRef.current;
//       const w10 = mapWrap.clientWidth / 10;
//       const h10 = mapWrap.clientWidth / (imageWidth / imageHeight) / 10;

//       const maxTranslateX = 0;
//       const minTranslateX = -(scale - 1) * 10 * w10;
//       const newTranslateX = Math.max(
//         minTranslateX,
//         Math.min(maxTranslateX, newX)
//       );

//       const maxTranslateY = 0;
//       const minTranslateY = -(scale - initialScale + 1 - 1) * 10 * h10;
//       const newTranslateY = Math.max(
//         minTranslateY,
//         Math.min(maxTranslateY, newY)
//       );

//       setTranslateX(newTranslateX);
//       setTranslateY(newTranslateY);
//       mapRef.current.style.transform = `translate3d(${newTranslateX}px, ${newTranslateY}px, 0px) scale(${scale})`;
//     },
//     [isDragging, scale, initialScale, imageWidth]
//   );

//   const handleEnd = useCallback(() => {
//     setIsDragging(false);
//     mapRef.current.style.cursor = "grab";
//   }, []);

//   useEffect(() => {
//     const mapWrap = mapWrapRef.current;

//     const handleTouchMove = (e) => handleMove(e);
//     const handleTouchStart = (e) => handleStart(e);
//     const handleTouchEnd = () => handleEnd();

//     document.addEventListener("mousemove", handleMove);
//     document.addEventListener("mouseup", handleEnd);
//     mapWrap.addEventListener("touchmove", handleTouchMove);
//     mapWrap.addEventListener("touchstart", handleTouchStart);
//     mapWrap.addEventListener("touchend", handleTouchEnd);

//     return () => {
//       document.removeEventListener("mousemove", handleMove);
//       document.removeEventListener("mouseup", handleEnd);
//       mapWrap.removeEventListener("touchmove", handleTouchMove);
//       mapWrap.removeEventListener("touchstart", handleTouchStart);
//       mapWrap.removeEventListener("touchend", handleTouchEnd);
//     };
//   }, [handleMove, handleStart, handleEnd]);

//   const handleWheel = useCallback(
//     (e) => {
//       const zoomAmount = 0.1;
//       const oldScale = scale;

//       let newScale;
//       if (e.deltaY < 0) {
//         newScale = scale + zoomAmount;
//       } else {
//         newScale = Math.max(initialScale, scale - zoomAmount);
//       }

//       const newWidth = imageWidth * newScale;
//       const newHeight = imageHeight * newScale;

//       const mapWrap = mapWrapRef.current;
//       const offsetX = (mapWrap.clientWidth / 2 - translateX) / oldScale;
//       const offsetY = (mapWrap.clientHeight / 2 - translateY) / oldScale;

//       const newTranslateX = translateX - offsetX * (newScale - oldScale);
//       const newTranslateY = translateY - offsetY * (newScale - oldScale);

//       const maxTranslateX = 0;
//       const minTranslateX = mapWrap.clientWidth - newWidth;
//       const maxTranslateY = 0;
//       const minTranslateY = mapWrap.clientHeight - newHeight;

//       const boundedTranslateX = Math.max(
//         minTranslateX,
//         Math.min(maxTranslateX, newTranslateX)
//       );
//       const boundedTranslateY = Math.max(
//         minTranslateY,
//         Math.min(maxTranslateY, newTranslateY)
//       );

//       setScale(newScale);
//       setTranslateX(boundedTranslateX);
//       setTranslateY(boundedTranslateY);
//       mapRef.current.style.transform = `translate3d(${boundedTranslateX}px, ${boundedTranslateY}px, 0px) scale(${newScale})`;
//     },
//     [scale, initialScale, translateX, translateY, imageWidth, imageHeight]
//   );

//   return (
//     <>
//       <div
//         className="map_wrap block lg:hidden"
//         ref={mapWrapRef}
//         onMouseDown={handleStart}
//         onTouchStart={handleStart}
//         onWheel={handleWheel}
//       >
//         <div className="map_container" ref={mapRef}>
//           <img src={map} alt="fullmap" className="map" />
//         </div>
//       </div>
//       <img src={map} alt="fullmap" className="w-full hidden lg:block" />
//     </>
//   );
// };

// export default MapZD;

import { useState, useRef, useEffect, useCallback } from "react";

const MapZD = ({ map, imageWidth, imageHeight, Svg }) => {
  const mapWrapRef = useRef(null);
  const mapRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [scale, setScale] = useState(1);
  const [initialScale, setInitialScale] = useState(1);
  const startXRef = useRef(0);
  const startYRef = useRef(0);
  const pinchStartDistanceRef = useRef(0);
  const lastScaleRef = useRef(1);

  useEffect(() => {
    const mapWrap = mapWrapRef.current;
    const map = mapRef.current;

    if (!mapWrap || !map) return;

    const H = mapWrap.clientWidth / (imageWidth / imageHeight);
    const viewH = mapWrap.clientHeight;

    const W = mapWrap.clientHeight / (imageHeight / imageWidth);
    const viewW = mapWrap.clientWidth;

    if (H / viewH) {
      const rateH = (Math.ceil((1 / (H / viewH)) * 100) / 100).toFixed(2);
      const initialScaleValue = Number(rateH);
      setInitialScale(initialScaleValue);
      setScale(initialScaleValue);
      map.style.transform = `scale(${initialScaleValue})`;
    } else {
      const rateW = (Math.ceil((1 / (H / viewH)) * 100) / 100).toFixed(2);
      const initialScaleValue = Number(rateW);
      setInitialScale(initialScaleValue);
      setScale(initialScaleValue);
      map.style.transform = `scale(${initialScaleValue})`;
    }
  }, [imageWidth, imageHeight]);

  const getPinchDistance = (touches) => {
    const [touch1, touch2] = touches;
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const handleStart = useCallback(
    (e) => {
      if (e.touches && e.touches.length === 2) {
        // Pinch-to-zoom start
        const distance = getPinchDistance(e.touches);
        pinchStartDistanceRef.current = distance;
        lastScaleRef.current = scale;
      } else if (e.touches && e.touches.length === 1) {
        // Drag start
        setIsDragging(true);
        const clientX = e.touches[0].clientX;
        const clientY = e.touches[0].clientY;
        startXRef.current = clientX - translateX;
        startYRef.current = clientY - translateY;
        mapRef.current.style.cursor = "grabbing";
      }
    },
    [translateX, translateY, scale]
  );

  const handleMove = useCallback(
    (e) => {
      if (e.touches && e.touches.length === 2) {
        // Handle pinch-to-zoom
        const distance = getPinchDistance(e.touches);
        const pinchRatio = distance / pinchStartDistanceRef.current;
        const newScale = Math.max(
          initialScale,
          lastScaleRef.current * pinchRatio
        );

        const mapWrap = mapWrapRef.current;
        const newWidth = imageWidth * newScale;
        const newHeight = imageHeight * newScale;

        const offsetX = (mapWrap.clientWidth / 2 - translateX) / scale;
        const offsetY = (mapWrap.clientHeight / 2 - translateY) / scale;

        const newTranslateX = translateX - offsetX * (newScale - scale);
        const newTranslateY = translateY - offsetY * (newScale - scale);

        const maxTranslateX = 0;
        const minTranslateX = mapWrap.clientWidth - newWidth;
        const maxTranslateY = 0;
        const minTranslateY = mapWrap.clientHeight - newHeight;

        const boundedTranslateX = Math.max(
          minTranslateX,
          Math.min(maxTranslateX, newTranslateX)
        );
        const boundedTranslateY = Math.max(
          minTranslateY,
          Math.min(maxTranslateY, newTranslateY)
        );

        setScale(newScale);
        setTranslateX(boundedTranslateX);
        setTranslateY(boundedTranslateY);
        mapRef.current.style.transform = `translate3d(${boundedTranslateX}px, ${boundedTranslateY}px, 0px) scale(${newScale})`;
      } else if (isDragging && e.touches && e.touches.length === 1) {
        // Handle drag
        const clientX = e.touches[0].clientX;
        const clientY = e.touches[0].clientY;

        const newX = clientX - startXRef.current;
        const newY = clientY - startYRef.current;

        const mapWrap = mapWrapRef.current;
        const w10 = mapWrap.clientWidth / 10;
        const h10 = mapWrap.clientWidth / (imageWidth / imageHeight) / 10;

        const maxTranslateX = 0;
        const minTranslateX = -(scale - 1) * 10 * w10;
        const newTranslateX = Math.max(
          minTranslateX,
          Math.min(maxTranslateX, newX)
        );

        const maxTranslateY = 0;
        const minTranslateY = -(scale - initialScale + 1 - 1) * 10 * h10;
        const newTranslateY = Math.max(
          minTranslateY,
          Math.min(maxTranslateY, newY)
        );

        setTranslateX(newTranslateX);
        setTranslateY(newTranslateY);
        mapRef.current.style.transform = `translate3d(${newTranslateX}px, ${newTranslateY}px, 0px) scale(${scale})`;
      }
    },
    [isDragging, scale, initialScale, imageWidth, translateX, translateY]
  );

  const handleEnd = useCallback(() => {
    setIsDragging(false);
    mapRef.current.style.cursor = "grab";
  }, []);

  useEffect(() => {
    const mapWrap = mapWrapRef.current;

    const handleTouchMove = (e) => handleMove(e);
    const handleTouchStart = (e) => handleStart(e);
    const handleTouchEnd = () => handleEnd();

    mapWrap.addEventListener("touchmove", handleTouchMove);
    mapWrap.addEventListener("touchstart", handleTouchStart);
    mapWrap.addEventListener("touchend", handleTouchEnd);

    return () => {
      mapWrap.removeEventListener("touchmove", handleTouchMove);
      mapWrap.removeEventListener("touchstart", handleTouchStart);
      mapWrap.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleMove, handleStart, handleEnd]);

  return (
    <>
      <div
        className="map_wrap block lg:hidden"
        ref={mapWrapRef}
        onTouchStart={handleStart}
      >
        <div className="map_container" ref={mapRef}>
          <img src={map} alt="fullmap" className="map absolute" />
          <Svg />
        </div>
      </div>
      <div className="hidden lg:block w-full h-full relative">
        <img
          src={map}
          alt="fullmap"
          className="w-full absolute hidden lg:block"
        />
        <Svg />
      </div>
    </>
  );
};

export default MapZD;
