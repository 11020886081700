import { configureStore } from "@reduxjs/toolkit";
import { languageReducer } from "./features/LanguageSlice";
// import GuideReducer from "./features/GuideSlice";
// 

const store = configureStore({
  reducer: {
    // guide: GuideReducer,
    language: languageReducer
  },
});

export default store;
