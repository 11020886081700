import React from "react";

import "../../App.css";
import { useSelector } from "react-redux";

const HomeBinhMinh = () => {
  const { locale } = useSelector((state) => state.language);

  return (
    <div className="relative h-screen overflow-hidden flex items-center justify-center">
      {/* <Guide360/> */}
      <iframe
        src={`${
          locale === "vi"
            ? "/file360/binhminh/binhminh-toan-canh/index.html"
            : "/file360/binhminh/binhminh-toan-canh/index.html"
        }`}
        title="binhminh-toan-canh"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default HomeBinhMinh;
