import React from "react";

const EbrochureBinhMinh = () => {
  const ebrouchureImages = Array.from({ length: 21 }, (_, i) => ({
    img: require(`../../assets/images/binhminh/ebrochure/${i + 1}.jpg`),
  }));

  return (
    <div className="max-h-[92vh] overflow-scroll">
      <div className="">
        {ebrouchureImages.map((item) => (
          <img
            key={item.img}
            src={item.img}
            className="w-full"
            alt="ebrochure"
            loading="lazy"
          />
        ))}
      </div>
    </div>
  );
};

export default EbrochureBinhMinh;
