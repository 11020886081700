import imgSlide from "../assets/images/slideImage.png";
import thumb_wisteria from "../assets/images/thumb_wisteria.jpg";
import bgTest3 from "../assets/images/bgTest3.png";
import thinhvuong from "../assets/images/thinhvuong.png";

import "../App.css";

import React, { useState, useRef, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const properties = [
  { name: "THE WISTERIA", image: thumb_wisteria, path: "/the-wisteria" },
  { name: "BÌNH MINH", image: imgSlide, path: "/binh-minh" },
  { name: "THỊNH VƯỢNG", image: thinhvuong, path: "/thinh-vuong" },
  { name: "THẢO VIÊN", image: bgTest3, path: "/thao-vien" },
  { name: "HOÀNG GIA 1", image: imgSlide, path: "/subdivisions" },
  { name: "HOÀNG GIA 2", image: imgSlide, path: "/subdivisions" },
  { name: "HỖN HỢP", image: imgSlide, path: "/subdivisions" },
  { name: "BIỆT THỰ", image: imgSlide, path: "/subdivisions" },
];

const PropertySlide = ({ property, navigate }) => (
  <div
    className="bg-leafbrown bg-cover bg-center bg-white h-[85%] rounded-3xl shadow-md p-4 m-2 w-[80%] md:w-[28%] 
  flex-shrink-0 flex flex-col justify-between items-center select-none border-2 border-yellow-500"
  >
    <img
      src={property.image}
      alt={property.name}
      className="imgSlide h-full object-cover rounded-t-lg"
    />
    <h3 className="text-xl md:text-3xl font-bold my-4 text-center text-[#AE6D42]">
      {property.name}
    </h3>
    <div
      className="flex items-center justify-center text-center
                text-primary bg-gradientMain shadow-2xl 
                  py-3 px-14 rounded-xl font-bold uppercase cursor-pointer text-base md:text-xl hover:opacity-85"
      onClick={() => navigate(property.path)}
    >
      XEM NGAY
    </div>
  </div>
);

const Subdivision = () => {
  const navigate = useNavigate();

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const slideRef = useRef(null);

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartX(e.type.includes("mouse") ? e.pageX : e.touches[0].pageX);
    setScrollLeft(slideRef.current.scrollLeft);
    slideRef.current.style.cursor = "grabbing";
  };

  const handleDragMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.type.includes("mouse") ? e.pageX : e.touches[0].pageX;
    const walk = (startX - x) * 2;
    slideRef.current.scrollLeft = scrollLeft + walk;
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    slideRef.current.style.cursor = "grab";
  };

  const scrollSlide = (direction) => {
    const scrollAmount =
      direction === "left"
        ? -slideRef.current.offsetWidth
        : slideRef.current.offsetWidth;
    slideRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  useEffect(() => {
    const slider = slideRef.current;
    slider.addEventListener("touchstart", handleDragStart, { passive: true });
    slider.addEventListener("touchmove", handleDragMove, { passive: false });
    slider.addEventListener("touchend", handleDragEnd);

    return () => {
      slider.removeEventListener("touchstart", handleDragStart);
      slider.removeEventListener("touchmove", handleDragMove);
      slider.removeEventListener("touchend", handleDragEnd);
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div className="bg-leafwhite bg-[#d7b6a0] bg-cover bg-fixed bg-center h-screen px-0 md:px-6 pt-5 pb-[11vh]">
      <div className="relative overflow-hidden h-[80%] flex flex-col top-1/2 -translate-y-1/2">
        <div
          className="flex overflow-x-auto scrollbar-hide flex-grow"
          style={{ cursor: "grab" }}
          ref={slideRef}
          onMouseDown={handleDragStart}
          onMouseMove={handleDragMove}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
        >
          {properties.map((property, index) => (
            <PropertySlide
              key={index}
              property={property}
              navigate={navigate}
            />
          ))}
        </div>
        <div className="flex gap-40 md:gap-60 absolute bottom-1 md:bottom-0 left-1/2 -translate-x-1/2">
          <div
            onClick={() => scrollSlide("left")}
            className=" bg-gradientMain rounded-full p-[2px] shadow-md w-12 h-12 md:w-14 md:h-14 flex items-center justify-center cursor-pointer"
            aria-label="Previous slide"
          >
            <div className="bg-white w-full h-full rounded-full flex items-center justify-center">
              <ChevronLeft className="text-amber-800 w-6 h-6 md:w-8 md:h-8" />
            </div>
          </div>
          <div
            onClick={() => scrollSlide("right")}
            className=" bg-gradientMain rounded-full p-[2px] shadow-md w-12 h-12 md:w-14 md:h-14 flex items-center justify-center cursor-pointer"
            aria-label="Previous slide"
          >
            <div className="bg-white w-full h-full rounded-full flex items-center justify-center">
              <ChevronRight className="text-amber-800 w-6 h-6 md:w-8 md:h-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subdivision;
