import React from "react";

const Video = ({ item, index, tabActive, setIsShowPopUp, setCurrentMedia }) => {
  return (
    <div
      className="relative bg-[#282f39] rounded-lg cursor-pointer transition-all group hover:scale-[1.02]"
      onClick={() => {
        setIsShowPopUp(true);
        setCurrentMedia(item);
      }}
    >
      <img
        src={item.thumb}
        alt={`${tabActive.title}${index}`}
        className="w-full h-[300px] object-cover rounded-lg"
      />
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div
          className="w-14 h-14 bg-[#fff] bg-opacity-35 backdrop-blur-sm rounded-full 
                    grid place-items-center transition group-hover:bg-opacity-60"
        >
          <svg
            className="ml-1 w-5"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Video;
