import React from "react";
import Location from "../LocationTemplate";

const UtilThaoVien = () => {

  return (
    <>
      <Location/>
    </>
  );
};

export default UtilThaoVien;
