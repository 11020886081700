import React, { useState } from "react";

const Util = () => {
  const tabs = [
    {
      name: "Cổng chào HRP",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_05020_01",
    },
    {
      name: "Ngã 4 công viên trung tâm",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_04922_15",
    },
    {
      name: "Dãy shophouse",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_04934_14",
    },
    {
      name: "Văn phòng bán hàng HRP",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_04961_16",
    },
    {
      name: "Khu biệt thự mẫu HRP",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_04973_11",
    },
    {
      name: "Vườn hoa công viên",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_04985_10",
    },
    {
      name: "Dãy shophouse",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_05063_06",
    },
    {
      name: "Khu biệt thự liền kề",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_05090_08",
    },
    {
      name: "Khu vực công viên bờ hồ",
      src: "/file360/hinode-royal-park-tien-ich/index.html?startscene=scene_05114_09",
    },
  ];

  const [tabActive, setTabActive] = useState(0);

  return (
    <div className="h-screen relative">
      <div
        className={`fixed z-50 w-[95%] md:w-[92%] left-1/2 bottom-[11vh] -translate-x-1/2 grid grid-cols-3 md:grid-cols-5 gap-1 md:gap-2 p-1 md:p-2 bg-[#fff] bg-opacity-20 backdrop-blur-sm border border-white rounded-xl`}
      >
        {tabs.map((item, index) => {
          return (
            <div
              key={index}
              className={`px-1 md:px-2 lg:px-4 flex items-center justify-center text-center
                    py-1 md:py-2 rounded-lg md:rounded-xl font-bold uppercase cursor-pointer
                    hover:bg-gradientMain hover:text-primary text-[10px] md:text-xs lg:text-[13px]
                    ${tabActive === index ? "bg-gradientMain text-primary" : "bg-white text-slate-800"}
                    `}
              onClick={() => setTabActive(index)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
      <iframe
        src={tabs[tabActive].src}
        title="hinode-royal-park-tien-ich"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default Util;
