import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import iconDown from "../../assets/images/downIcon.png";

const HouseWisteria = () => {
  const [tabActive, setTabActive] = useState({
    parent: 0,
    children: 0,
  });

  const navigate = useNavigate();

  const houses = [
    {
      title: "Tòa W1",
      children: [
        {
          title: "Căn W1-03",
          src: "/file360/wisteria/houses/w1-03/index.html",
          parent: 0,
        },
        {
          title: "Căn W1-09",
          src: "/file360/wisteria/houses/w1-09/index.html",
          parent: 0,
        },
        {
          title: "Căn W1-10",
          src: "/file360/wisteria/houses/w1-10/index.html",
          parent: 0,
        },
        {
          title: "Căn W1-11",
          src: "/file360/wisteria/houses/W1-11/index.html",
          parent: 0,
        },
      ],
    },
    {
      title: "Tòa W2",
      children: [
        {
          title: "Căn W2-01",
          src: "/file360/wisteria/houses/w2-01/index.html",
          parent: 1,
        },
        {
          title: "Căn W2-02",
          src: "/file360/wisteria/houses/w2-02/index.html",
          parent: 1,
        },
      ],
    },
    {
      title: "Tòa W3",
      children: [],
    },
  ];

  return (
    <div className="h-screen relative">
      <iframe
        src={houses[tabActive.parent].children[tabActive.children].src}
        title="can ho"
        width="100%"
        height="100%"
      />
      <div
        className={`mb-8 flex justify-center items-center fixed z-40 top-4 left-1/2 -translate-x-1/2 w-[65%] lg:w-[35%]`}
      >
        <div
          className={`w-full grid grid-cols-3 md:grid-cols-3 gap-1 md:gap-2 p-1 md:p-2 bg-white bg-opacity-20 backdrop-blur-sm border border-white
            rounded-xl md:rounded-2xl`}
        >
          {houses.map((house, index) => (
            <div
              key={index}
              className={`flex items-center justify-center gap-2 md:gap-4 text-center text-[10px] md:text-base
                ${
                  tabActive.parent === index
                    ? "text-primary bg-gradientNavWisteria"
                    : "text-sub bg-white"
                }
                    hover:text-primary hover:bg-gradientNavWisteria hover:shadow-2xl 
                      py-2 rounded-lg md:rounded-xl font-bold uppercase cursor-pointer relative group`}
              onClick={() => {
                if (houses[index].children.length === 0) return;
                setTabActive({
                  parent: index,
                  children: 0,
                });
              }}
            >
              {house.title}
              <img src={iconDown} alt="iconDown" className="w-3" />
              {house.children.length !== 0 && (
                <div className="hidden absolute z-[41] top-5 md:top-10 left-0 w-full group-hover:block">
                  <div className="h-4 w-full"></div>
                  <div
                    className="flex flex-col gap-1 md:gap-2 w-full p-1 md:p-2 bg-[#fff] bg-opacity-20 
                  backdrop-blur-md border border-white rounded-xl"
                  >
                    {house.children.map((item, index) => (
                      <div
                        key={index}
                        className={`text-center ${
                          tabActive.children === index &&
                          tabActive.parent === item.parent
                            ? "text-primary bg-gradientNavWisteria"
                            : "text-sub bg-white"
                        }
                    hover:text-primary hover:bg-gradientNavWisteria hover:shadow-2xl 
                      py-2 rounded-lg md:rounded-xl font-bold uppercase cursor-pointer`}
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log({
                            parent: item.parent,
                            children: index,
                          });
                          setTabActive({
                            parent: item.parent,
                            children: index,
                          });
                        }}
                      >
                        {item.title}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HouseWisteria;
