import { useNavigate } from "react-router-dom";

import blurLoading from "../assets/images/blurLoading.png";

export default function Loading() {
  const navigate = useNavigate();

    setTimeout(() => {
      navigate("/tour");
    }, 2000);

  return (
    <div className="bg-[url('assets/images/bgLoading.jpg')] bg-cover bg-fixed bg-center h-screen relative">
      <div>
        <div
          className="w-16 h-16 border-[6px] border-[#FBE67B] border-dotted rounded-full animate-spin border-t-transparent
                    absolute bottom-24 left-1/2 z-40
          "
        ></div>
      </div>
      <img
        src={blurLoading}
        alt="blurLoading"
        className="absolute bottom-0 left-0"
      />
    </div>
  );
}
