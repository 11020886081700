import React, { useState } from "react";
import PopUpMedia from "../../components/PopUpMedia";

const HouseThaoVien = () => {
  const items = Array.from({ length: 14 }, (_, i) => ({
    thumb: require(`../../assets/images/thaovien/nhamau/${i + 1}.png`),
    src: require(`../../assets/images/thaovien/nhamau/${i + 1}.png`),
    type: "image"
  }));

  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [currentMedia, setCurrentMedia] = useState();
  return (
    <div className="bg-leafwhite bg-[#d7b6a0] bg-cover bg-fixed bg-center min-h-screen px-6 pt-24">

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-h-[80vh] overflow-scroll px-2 pb-24 md:pb-8 pt-2">
        {items.map((item, index) => {
          return (
                <div
                  className="relative bg-[#282f39] rounded-lg cursor-pointer transition-all group hover:scale-[1.02]"
                  onClick={() => {
                    setIsShowPopUp(true);
                    setCurrentMedia(item);
                  }}
                >
                  <img
                    src={item.thumb}
                    alt={index}
                    className="w-full h-[300px] object-cover rounded-lg"
                  />
                </div>
          );
        })}
      </div>
      {isShowPopUp && (
        <PopUpMedia
          type={currentMedia.type}
          src={currentMedia.src}
          setIsShowPopUp={setIsShowPopUp}
        />
      )}
    </div>
  );
};

export default HouseThaoVien;
