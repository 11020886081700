import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { useState } from "react";
import PopUpGuide from "../../components/PopUpGuide";

import tour from "../../assets/images/iconNav-wisteria/tour.png";
import houses from "../../assets/images/iconNav-wisteria/houses.png";
import ground from "../../assets/images/iconNav-wisteria/ground.png";
import utils from "../../assets/images/iconNav-wisteria/utils.png";
import ebrochure from "../../assets/images/iconNav-wisteria/ebrochure.png";

import tourHover from "../../assets/images/icon-binhminh/tourHover.png";
import housesHover from "../../assets/images/icon-binhminh/houseHover.png";
import groundHover from "../../assets/images/icon-binhminh/groundHover.png";
import utilsHover from "../../assets/images/icon-binhminh/utilHover.png";
import ebrochureHover from "../../assets/images/icon-binhminh/ebrochureHover.png";

import btnBack from "../../assets/images/btnBack.png"

export default function LayoutBinhMinh() {
  const menu = [
    {
      label: "Toàn cảnh",
      path: "/binh-minh",
      icon: tour,
      iconHover: tourHover,
    },
    {
      label: "Tiện ích",
      path: "/binh-minh/utilities",
      icon: utils,
      iconHover: utilsHover,
    },
    {
      label: "Mặt bằng",
      path: "/binh-minh/ground",
      icon: ground,
      iconHover: groundHover,
    },
    {
      label: "Nhà Mẫu",
      path: "/binh-minh/houses",
      icon: houses,
      iconHover: housesHover,
    },
    {
      label: "E-Brochure",
      path: "/binh-minh/ebrochure",
      icon: ebrochure,
      iconHover: ebrochureHover,
    },
  ];

  // const [isShowPopUp, setIsShowPopUp] = useState(false);

  const path = useLocation().pathname;

  const handleOpenPopUp = () => {
    if (path === "/binh-minh/ebrochure") {
      return;
    }
    // setIsShowPopUp(true);
  };

  const navigate = useNavigate();


  return (
    <div className="h-screen relative">
      {/* {isShowPopUp && <PopUpGuide setIsShowPopUp={setIsShowPopUp} />} */}
      <Outlet />
      <img
        src={btnBack}
        alt="btnBack"
        className="absolute z-[45] top-2 left-2 md:top-4 md:left-4 cursor-pointer w-12 h-12 md:w-20 md:h-20 transition hover:opacity-90"
        onClick={() => navigate("/tour")}
      />

      <NavBar
        menu={menu}
        logoText={"Bình minh"}
        background={"bg-leafwhiteNav bg-[#000c38]"}
        colorHover={"text-[#000c38]"}
        theme="dark"
        setIsShowPopUp={handleOpenPopUp}
        backgroundActive={"bg-gradientMain"}
        backgroundHover={"hover:bg-gradientMain"}
        backgroundBorder={"bg-gradientMain"}
      />
    </div>
  );
}
