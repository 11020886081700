import React, { useState } from "react";
import map_util from "../../assets/images/wisteria/tien-ich/tienich.png";
import { hovers, icons } from "../../constants/data_util_wisteria";
import { IconUtil } from "../../components/Wisteria/IconUtil";

const UtilWisteria = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const utils = [
    {
      floor: "01",
      util: [
        { id: "01", name: "TRUNG TÂM THƯƠNG MẠI - SHOPHOUSE TMDV" },
        { id: "02", name: "QUẢNG TRƯỜNG TRUNG TÂM" },
        { id: "03", name: "SÂN CHƠI TRẺ EM" },
        { id: "04", name: "SÂN THỂ THAO NGOÀI TRỜI" },
      ],
    },
    {
      floor: "06",
      util: [
        { id: "05", name: "BỂ BƠI" },
        { id: "06", name: "PHÒNG TẮM, THAY ĐỒ" },
        { id: "07", name: "PHÒNG SINH HOẠT CỘNG ĐỒNG" },
        { id: "08", name: "THƯ VIỆN" },
        { id: "09", name: "VƯỜN DẠO BỘ" },
      ],
    },
  ];

  const handleMouseEnter = (id) => {
    setHoveredIcon(id);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  return (
    <div className="max-h-screen h-screen w-screen overflow-hidden bg-[#50184b] bg-opacity-80">
      <div className="w-[230%] left-[80%] -translate-x-1/2 md:left-0 md:translate-x-0 md:w-full h-screen overflow-hidden relative flex items-center">
        <img src={map_util} alt="fullmap" className="absolute w-full" />
        <svg
          className="w-full absolute"
          viewBox="0 0 1920 1080"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* ICONS */}
          {icons.map((icon, index) => (
            <IconUtil
              key={index}
              index={index}
              id={icon.id}
              cx={icon.cx}
              cy={icon.cy}
              path1={icon.path}
              path2={hovers[index].d1}
              path3={hovers[index].d2}
              width={hovers[index].width}
              handleMouseEnter={handleMouseEnter}
              // handleMouseLeave={handleMouseLeave}
              isHovered={hoveredIcon === icon.id}
            />
          ))}
          {/* Render SVG hover ở cuối để nó nằm trên cùng */}
          {hoveredIcon &&
            hovers
              .filter((item) => item.id === hoveredIcon)
              .map((item) => (
                <g onMouseLeave={handleMouseLeave} className=" cursor-pointer">
                  <rect
                    x={item.x}
                    y={item.y}
                    width={item.width}
                    height={48}
                    rx="24"
                    fill={`url(#paint${
                      hovers.indexOf(item) * 2
                    }_linear_783_8044)`}
                  />
                  <circle
                    cx={item.x + 24}
                    cy={item.y + 24}
                    r="22.5"
                    fill={`url(#paint${
                      hovers.indexOf(item) * 2 + 1
                    }_linear_783_8044)`}
                    stroke="#50184B"
                    strokeWidth="3"
                  />
                  <path d={item.d1} fill="#50184B" />
                  <path d={item.d2} fill="#50184B" />
                </g>
              ))}

          {/* //////////////////////////// */}
          <defs>
            <linearGradient
              id="paint0_linear_766_8227"
              x1="825.287"
              y1="224.871"
              x2="1108.17"
              y2="406.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_766_8227"
              x1="837.198"
              y1="255.819"
              x2="912.271"
              y2="378.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_766_8227"
              x1="614.287"
              y1="514.871"
              x2="897.171"
              y2="696.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_766_8227"
              x1="626.198"
              y1="545.819"
              x2="701.271"
              y2="668.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_766_8227"
              x1="747.287"
              y1="516.871"
              x2="1030.17"
              y2="698.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_766_8227"
              x1="759.198"
              y1="547.819"
              x2="834.271"
              y2="670.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_766_8227"
              x1="592.287"
              y1="407.871"
              x2="875.171"
              y2="589.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_766_8227"
              x1="604.198"
              y1="438.819"
              x2="679.271"
              y2="561.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_766_8227"
              x1="699.287"
              y1="389.871"
              x2="982.171"
              y2="571.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_766_8227"
              x1="711.198"
              y1="420.819"
              x2="786.271"
              y2="543.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_766_8227"
              x1="806.287"
              y1="382.871"
              x2="1089.17"
              y2="564.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_766_8227"
              x1="818.198"
              y1="413.819"
              x2="893.271"
              y2="536.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_766_8227"
              x1="666.287"
              y1="287.871"
              x2="949.171"
              y2="469.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_766_8227"
              x1="678.198"
              y1="318.819"
              x2="753.271"
              y2="441.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_766_8227"
              x1="646.287"
              y1="238.871"
              x2="929.171"
              y2="420.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint15_linear_766_8227"
              x1="658.198"
              y1="269.819"
              x2="733.271"
              y2="392.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint16_linear_766_8227"
              x1="873.287"
              y1="391.871"
              x2="1156.17"
              y2="573.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint17_linear_766_8227"
              x1="885.198"
              y1="422.819"
              x2="960.271"
              y2="545.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint18_linear_766_8227"
              x1="924.287"
              y1="381.871"
              x2="1207.17"
              y2="563.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint19_linear_766_8227"
              x1="936.198"
              y1="412.819"
              x2="1011.27"
              y2="535.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint20_linear_766_8227"
              x1="1001.29"
              y1="406.871"
              x2="1284.17"
              y2="588.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint21_linear_766_8227"
              x1="1013.2"
              y1="437.819"
              x2="1088.27"
              y2="560.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint22_linear_766_8227"
              x1="691.287"
              y1="587.871"
              x2="974.171"
              y2="769.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint23_linear_766_8227"
              x1="703.198"
              y1="618.819"
              x2="778.271"
              y2="741.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint24_linear_766_8227"
              x1="800.287"
              y1="703.871"
              x2="1083.17"
              y2="885.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint25_linear_766_8227"
              x1="812.198"
              y1="734.819"
              x2="887.271"
              y2="857.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint26_linear_766_8227"
              x1="440.287"
              y1="527.871"
              x2="723.171"
              y2="709.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint27_linear_766_8227"
              x1="452.198"
              y1="558.819"
              x2="527.271"
              y2="681.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint28_linear_766_8227"
              x1="318.287"
              y1="430.871"
              x2="601.171"
              y2="612.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint29_linear_766_8227"
              x1="330.198"
              y1="461.819"
              x2="405.271"
              y2="584.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint30_linear_766_8227"
              x1="532.287"
              y1="143.871"
              x2="815.171"
              y2="325.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint31_linear_766_8227"
              x1="544.198"
              y1="174.819"
              x2="619.271"
              y2="297.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint0_linear_783_8044"
              x1="676.017"
              y1="224.871"
              x2="766.243"
              y2="833.542"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_783_8044"
              x1="825.287"
              y1="224.871"
              x2="1108.17"
              y2="406.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_783_8044"
              x1="516.738"
              y1="514.871"
              x2="645.07"
              y2="1109.21"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_783_8044"
              x1="614.287"
              y1="514.871"
              x2="897.171"
              y2="696.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_783_8044"
              x1="679.49"
              y1="516.871"
              x2="850.715"
              y2="1087.54"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_783_8044"
              x1="745.287"
              y1="516.871"
              x2="1028.17"
              y2="698.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_783_8044"
              x1="493.738"
              y1="407.871"
              x2="622.07"
              y2="1002.21"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_783_8044"
              x1="591.287"
              y1="407.871"
              x2="874.171"
              y2="589.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_783_8044"
              x1="668.844"
              y1="389.871"
              x2="926.19"
              y2="875.559"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_783_8044"
              x1="699.287"
              y1="389.871"
              x2="982.171"
              y2="571.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_783_8044"
              x1="727.397"
              y1="382.871"
              x2="878.13"
              y2="965.95"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_783_8044"
              x1="806.287"
              y1="382.871"
              x2="1089.17"
              y2="564.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_783_8044"
              x1="587.397"
              y1="287.871"
              x2="738.13"
              y2="870.95"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_783_8044"
              x1="666.287"
              y1="287.871"
              x2="949.171"
              y2="469.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_783_8044"
              x1="540.881"
              y1="238.871"
              x2="661.564"
              y2="836.549"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint15_linear_783_8044"
              x1="646.287"
              y1="238.871"
              x2="929.171"
              y2="420.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint16_linear_783_8044"
              x1="767.881"
              y1="391.871"
              x2="888.564"
              y2="989.549"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint17_linear_783_8044"
              x1="873.287"
              y1="391.871"
              x2="1156.17"
              y2="573.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint18_linear_783_8044"
              x1="884.024"
              y1="381.871"
              x2="1112.23"
              y2="904.211"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint19_linear_783_8044"
              x1="924.287"
              y1="381.871"
              x2="1207.17"
              y2="563.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint20_linear_783_8044"
              x1="945.311"
              y1="406.871"
              x2="1135.4"
              y2="964.071"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint21_linear_783_8044"
              x1="1001.29"
              y1="406.871"
              x2="1284.17"
              y2="588.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint22_linear_783_8044"
              x1="635.311"
              y1="587.871"
              x2="825.395"
              y2="1145.07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint23_linear_783_8044"
              x1="691.287"
              y1="587.871"
              x2="974.171"
              y2="769.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint24_linear_783_8044"
              x1="744.311"
              y1="703.871"
              x2="934.395"
              y2="1261.07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint25_linear_783_8044"
              x1="800.287"
              y1="703.871"
              x2="1083.17"
              y2="885.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint26_linear_783_8044"
              x1="384.311"
              y1="527.871"
              x2="574.395"
              y2="1085.07"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint27_linear_783_8044"
              x1="440.287"
              y1="527.871"
              x2="723.171"
              y2="709.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint28_linear_783_8044"
              x1="262.311"
              y1="430.871"
              x2="452.395"
              y2="988.071"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint29_linear_783_8044"
              x1="318.287"
              y1="430.871"
              x2="601.171"
              y2="612.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint30_linear_783_8044"
              x1="475.311"
              y1="143.871"
              x2="665.395"
              y2="701.071"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
            <linearGradient
              id="paint31_linear_783_8044"
              x1="531.287"
              y1="143.871"
              x2="814.171"
              y2="325.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#C2AB87" />
              <stop offset="0.15" stop-color="#FFEFC6" />
              <stop offset="0.41" stop-color="#B08F58" />
              <stop offset="0.68" stop-color="#FFEFC6" />
              <stop offset="1" stop-color="#C2AB87" />
            </linearGradient>
          </defs>
        </svg>

        {/* Danh sách tiện ích */}
        <div className="fixed hidden md:block right-4 top-10 bg-[#50184B] p-4 bg-opacity-80 rounded-2xl border-2 border-[#FFEFC6]">
          <div className="titleUtil text-center uppercase bg-gradientNavWisteria bg-clip-text text-transparent mb-2">
            Tiện ích
          </div>
          {utils.map((item, index) => (
            <div className="text-white mb-4" key={index}>
              <p className="mb-1 uppercase">Tầng {item.floor}</p>
              <div className="h-[1px] bg-gradientNavWisteria w-full mb-4"></div>
              {item.util.map((item, index) => (
                // Thành phần tiện ích
                <div
                  key={index}
                  onMouseLeave={handleMouseLeave}
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  className={`flex mb-2 gap-2 items-center pr-2 rounded-full group cursor-pointer
                ${hoveredIcon === item.id ? "bg-gradientNavWisteria" : ""}
                `}
                >
                  <div
                    className={`rounded-full border-[3px]
                       ${
                         hoveredIcon === item.id
                           ? "border-[#50184B]"
                           : "border-[#FFEFC6]"
                       }
                `}
                  >
                    <div
                      className={`text-center text-sm uppercase w-8 h-8
                  font-bold flex items-center justify-center
                   ${
                     hoveredIcon === item.id
                       ? "text-[#50184B]"
                       : "bg-gradientNavWisteria bg-clip-text text-transparent"
                   }
                  `}
                    >
                      {item.id}
                    </div>
                  </div>
                  <div
                    className={`uppercase font-semibold text-sm 
                         ${hoveredIcon === item.id ? "text-[#50184B] " : ""}
                      `}
                  >
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UtilWisteria;
