export const IconUtil = ({
  index,
  id,
  cx,
  cy,
  width,
  path1,
  path2,
  path3,
  handleMouseEnter,
  handleMouseLeave,
  isHovered,
}) => (
  <g
    onMouseEnter={() => handleMouseEnter(id)}
    // onMouseLeave={handleMouseLeave}
    className="cursor-pointer"
  >
    {/* Vòng tròn chính */}
    <circle
      cx={cx}
      cy={cy}
      r="22.5"
      fill="#50184B"
      stroke={`url(#paint${index*2}_linear_766_8227)`}
      strokeWidth="3"
    />

    {/* Đường dẫn (path) của icon */}
    <path d={path1} fill={`url(#paint${index*2+1}_linear_766_8227)`} />

    {/* SVG hover nếu icon đang được hover */}
    {/* {isHovered && (
      <>
        <rect
          x={cx - 24}
          y={cy - 24}
          width={width}
          height={48}
          rx="24"
          fill={`url(#paint${index*2}_linear_770_8483)`}
        />
        <circle
          cx={cx}
          cy={cy}
          r="22.5"
          fill={`url(#paint${index*2+1}_linear_770_8483)`}
          stroke="#50184B"
          strokeWidth="3"
        />
        <path d={path2} fill="#50184B" />
        <path d={path3} fill="#50184B" />
      </>
    )} */}
  </g>
);
