import React from "react";

const SvgAnimation = ({ svgGroups }) => {
  return (
    <>
      {svgGroups.map((group) => (
        <g key={group.id} className="svg-group">
          {group.children.map((child, childIndex) => {
            if (child.type === "g") {
              return (
                <ChildGElement
                  key={`${group.id}-child-${childIndex}`}
                  child={child}
                  groupId={group.id}
                  childIndex={childIndex}
                />
              );
            }
            return (
              <path
                key={`${group.id}-path-${childIndex}`}
                {...child.attributes}
              />
            );
          })}
        </g>
      ))}
    </>
  );
};

const ChildGElement = ({ child, groupId }) => {

  return (
    <g>
      {child.paths.map((path, pathIndex) => (
        <path key={`${groupId}-path-${pathIndex}`} {...path} />
      ))}
    </g>
  );
};

export default SvgAnimation;