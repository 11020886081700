import React from "react";
import toancanh from "../../assets/images/wisteria/toancanh.png";

import "../../App.css";
import { useSelector } from "react-redux";
import Guide360 from "../../components/Guide360";

const GroundThinhVuong = () => {
  const { locale } = useSelector((state) => state.language);

  return (
    <div className="relative h-screen overflow-hidden flex items-center justify-center">
      {/* <Guide360/> */}
      <iframe
        src={`${
          locale === "vi"
            ? "/file360/thinhvuong/thinhvuong-mat-bang/index.html"
            : "/file360/thinhvuong/thinhvuong-mat-bang/index.html"
        }`}
        title="thinhvuong-mat-bang"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default GroundThinhVuong;
