import React from "react";
import "./Tab.css";
import TabItem from "./TabItem";

const Tab = ({
  tabs,
  tabActive,
  setTabActive,
  cols,
  isBlur = false,
  isBlurItem = false,
  // width = "65vw",
  className
}) => {
  return (
    <div className={`mb-6 flex justify-center items-center ${className}`}>
      <div
        className={`grid gap-1 md:gap-2 p-1 md:p-2 ${
          isBlur
            ? "bg-[#fff] bg-opacity-20 backdrop-blur-sm border border-white"
            : "bg-gray-500"
        } bg-opacity-60 rounded-md md:rounded-xl tabContainer w-auto`}
        style={{
          gridTemplateColumns: `repeat(${cols},1fr)`,
          // width: `${width}`,
        }}
      >
        {tabs.map((item, index) => {
          return (
            <TabItem
              key={index}
              tabActive={tabActive}
              item={item}
              setTabActive={setTabActive}
              isBlur={isBlurItem}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Tab;
