import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function NavItem({
  path,
  label,
  icon,
  iconHover,
  theme,
  colorHover,
  backgroundActive,
  backgroundHover
}) {
  const isActive = useLocation().pathname === path;
  const [isHover, setIsHover] = useState(false);

  return (
    <Link
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      to={path}
      className={`${
        isActive
          ? `${colorHover} ${backgroundActive} shadow-2xl md:col-span-2 lg:col-span-1`
          : `${
              isHover
                ? `${colorHover}`
                : theme === "dark"
                ? "text-white"
                : "text-sub"
            }`
      } font-bold flex justify-center items-center gap-3 cursor-pointer
        ${backgroundHover} hover:shadow-2xl`}
    >
      {isHover || isActive ? (
        <img className="w-6 h-6" alt={label} src={iconHover} />
      ) : (
        <img className="w-6 h-6" alt={label} src={icon} />
      )}
      <p
        className={`${isActive ? "md:block hidden" : "hidden lg:block"} ${
          isHover ? "hidden lg:block" : "hidden lg:block"
        } uppercase`}
      >
        {label}
      </p>
    </Link>
  );
}
